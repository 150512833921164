@font-face {
  font-family: "AppleSDGothicNeo";
  font-weight: 400;
  src: url("fonts/AppleSDGothicNeoL.ttf") format("truetype"),
    url("fonts/AppleSDGothicNeoL.woff") format("woff"),
    url("fonts/AppleSDGothicNeoL.woff2") format("woff2"),
    url("fonts/AppleSDGothicNeoL.eot");
}
@font-face {
  font-family: "AppleSDGothicNeo";
  font-weight: 500;
  src: url("fonts/AppleSDGothicNeoB.ttf") format("truetype"),
    url("fonts/AppleSDGothicNeoB.woff") format("woff"),
    url("fonts/AppleSDGothicNeoB.woff2") format("woff2"),
    url("fonts/AppleSDGothicNeoB.eot");
}
@font-face {
  font-family: "AppleSDGothicNeo";
  font-weight: 600;
  src: url("fonts/AppleSDGothicNeoM.ttf") format("truetype"),
    url("fonts/AppleSDGothicNeoM.woff") format("woff"),
    url("fonts/AppleSDGothicNeoM.woff2") format("woff2"),
    url("fonts/AppleSDGothicNeoM.eot");
}
@font-face {
  font-family: "AppleSDGothicNeo";
  font-weight: 700;
  src: url("fonts/AppleSDGothicNeoB.ttf") format("truetype"),
    url("fonts/AppleSDGothicNeoB.woff") format("woff"),
    url("fonts/AppleSDGothicNeoB.woff2") format("woff2"),
    url("fonts/AppleSDGothicNeoB.eot");
}
@font-face {
  font-family: "AppleSDGothicNeo";
  font-weight: 800;
  src: url("fonts/AppleSDGothicNeoEB.ttf") format("truetype"),
    url("fonts/AppleSDGothicNeoEB.woff") format("woff"),
    url("fonts/AppleSDGothicNeoEB.woff2") format("woff2"),
    url("fonts/AppleSDGothicNeoEB.eot");
}
@font-face {
  font-family: "AppleSDGothicNeo";
  font-weight: 900;
  src: url("fonts/AppleSDGothicNeoH.ttf") format("truetype"),
    url("fonts/AppleSDGothicNeoH.woff") format("woff"),
    url("fonts/AppleSDGothicNeoH.woff2") format("woff2"),
    url("fonts/AppleSDGothicNeoH.eot");
}

@font-face {
  font-family: "NotoSans";
  font-weight: 500;
  src: url("fonts/NotoSans-Light.ttf") format("truetype");
}
@font-face {
  font-family: "NotoSans";
  font-weight: 600;
  src: url("fonts/NotoSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "NotoSans";
  font-weight: 700;
  src: url("fonts/NotoSans-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "NotoSans";
  font-weight: 800;
  src: url("fonts/NotoSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "NotoSans";
  font-weight: 900;
  src: url("fonts/NotoSans-Black.ttf") format("truetype");
}

@font-face {
  font-family: "BlackHanSans";
  font-weight: 900;
  src: url("fonts/BlackHanSans-Regular.ttf") format("truetype");
}

*,
*::after,
*::before {
  outline: none;
}
body,
html {
  font-family: "AppleSDGothicNeo", "NotoSans", sans-serif;
  color: #555;
  outline: none;
  width: 100%;
  letter-spacing: -0.05em;
  word-break: keep-all;
}
body::-webkit-scrollbar {
  width: 8px;
}
body::-webkit-scrollbar-thumb {
  height: 30%;
  background: rgba(68, 68, 68, 0.4);
  border-radius: 10px;
}
body::-webkit-scrollbar-track {
  background: rgba(108, 108, 108, 0.2);
}

body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
p,
form,
fieldset,
input,
table,
tr,
th,
td,
ul,
li {
  margin: 0;
  padding: 0;
}
ul,
ol,
li {
  list-style: none;
}
img,
fieldset {
  border: none;
  vertical-align: top;
}
input {
  padding: 0;
}
a {
  text-decoration: none;
  cursor: pointer;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #555;
}
a:link,
a:visited {
  text-decoration: none;
  color: #555;
}
table {
  border-collapse: collapse;
}
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  box-sizing: border-box;
}
button {
  font-family: "AppleSDGothicNeo", sans-serif;
}
button,
button:hover {
  cursor: pointer;
  border: 0;
}

.gnb {
  display: flex;
}
/* .header {
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px;
    z-index:1;
} */

.main-pop .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background: none;
}

.main-pop .main-pop-wrap {
  display: block;
  position: absolute;
  /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
  /* background-color:#fff;
  padding:20px; */
  /* background-color:rgba(0,0,0,0.2); */
  border-radius: 20px;
}
.main-pop:nth-of-type(1) {
  z-index: 20;
}
.main-pop:nth-of-type(2) {
  z-index: 19;
}
.main-pop:nth-of-type(3) {
  z-index: 18;
}
.main-pop:nth-of-type(4) {
  z-index: 17;
}
.main-pop:nth-of-type(5) {
  z-index: 16;
}
.main-pop:nth-of-type(1) .main-pop-wrap {
  left: 130px;
  top: 50px;
}
.main-pop:nth-of-type(2) .main-pop-wrap {
  left: 180px;
  top: 90px;
}
.main-pop:nth-of-type(3) .main-pop-wrap {
  left: 250px;
  top: 50%;
  transform: translateY(-50%);
}
.main-pop:nth-of-type(4) .main-pop-wrap {
  left: 340px;
  top: 240px;
}
.main-pop:nth-of-type(5) .main-pop-wrap {
  left: 430px;
  top: 320px;
}
.main-pop-img {
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.8);
}
.main-pop-wrap img {
  width: 300px;
  display: block;
  background-color: #fff;
}
.main-pop-btn {
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.main-pop-btn > button {
  display: block;
  text-align: center;
  padding: 10px 0;
  background-color: #fff;
  border: 1px solid #ddd;
  color: #222;
  font-weight: 600;
  font-family: "AppleSDGothicNeo", "NotoSans", sans-serif;
  width: 48%;
  border-radius: 24px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
}
.main-pop-btn > button:hover {
  background-color: #efefef;
}
.main-pop-btn > button:nth-child(1) {
  background-color: #222;
  color: #fff;
  border: 0;
  border: 1px solid #222;
}
.main-pop-btn > button:nth-child(1):hover {
  background-color: #111;
  border: 1px solid #111;
}

.modal {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  width: 60%;
  height: 90vh;
  z-index: 10;
  overflow-y: scroll;
}
.close-btn {
  position: fixed;
  top: 30px;
  right: 20px;
  z-index: 15;
  text-indent: -9999px;
  width: 34px;
  height: 34px;
  background: transparent;
}
.close-btn::before,
.close-btn::after {
  display: block;
  position: absolute;
  left: 50%;
  top: 0;
  content: "";
  height: 34px;
  width: 2px;
  background-color: #000;
}
.close-btn::before {
  transform: rotate(45deg) translateX(-50%);
}
.close-btn::after {
  transform: rotate(-45deg) translateX(-50%);
}
.modal .submit-btn {
  background: #1b2a87;
  color: #fff;
  border: 0;
  border-radius: 0;
  display: block;
  padding: 12px 48px;
  margin: 0 auto;
  margin-top: 40px;
  font-size: 0.9em;
  box-shadow: none;
}

.pop-cefr {
  position: relative;
  width: 100%;
  padding: 8% 8%;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  width: 70%;
  height: 90vh;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 10;
  overflow-y: scroll;
  padding: 5% 5%; */
}
.modal::-webkit-scrollbar {
  width: 8px;
}
.modal::-webkit-scrollbar-thumb {
  height: 30%;
  background: #c7c7c7;

  border-radius: 10px;
}
.modal::-webkit-scrollbar-track {
  background: rgba(129, 129, 129, 0.1);
}

.pop-cefr h1 {
  margin-bottom: 30px;
  font-size: 1.8em;
  color: #000;
  font-weight: 700;
}
.pop-cefr .cefr-table {
  width: 100%;
}
.pop-cefr table {
}
.pop-cefr table thead {
  background: #f6f8ff;
  border-top: 3px solid #3b57ff;
  border-bottom: 1px solid #000;
}

.pop-cefr table tr td,
.pop-cefr table tr th {
  border: 1px solid #ddd;
  padding: 10px 15px;
  text-align: center;
  color: #000;
  font-weight: 600;
}
.pop-cefr table tr th {
  font-weight: 800;
}
.pop-cefr table tbody tr:hover {
  background: #fafbff;
}
.pop-cefr table tr td b {
  display: block;
}
.pop-cefr table tr td:first-child {
  border-left: 0;
}
.pop-cefr table tr th:first-child {
  border-left: 0;
}
.pop-cefr table tr td:last-child {
  border-right: 0;
}
.pop-cefr table tr th:last-child {
  border-right: 0;
}
.pop-cefr table tr td:nth-child(2) {
  text-align: left;
}
.pop-cefr table tr td.txt-center {
  text-align: center;
}

/*탑버튼*/
.top-button {
  display: block;
  position: fixed;
  bottom: -35px;
  right: 5%;
  background-color: #333;
  color: #fff;
  height: 35px;
  width: 80px;
  border-radius: 5px;
  /* border-top-left-radius: 20px;
  border-top-right-radius: 20px; */
  cursor: pointer;
  line-height: 42px;
  text-align: center;
  transition: all 0.5s;
}
.top-button span {
  margin: 0 auto;
}
.top-button.show {
  bottom: 0;
  z-index: 10;
}
/*폼메일*/
.form-mail {
  position: absolute;
  width: 100%;
  padding: 8% 8%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.form-mail .form {
  font-size: 1em;
}
.form-mail .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 0;
}
.form-mail .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  background: #fff;
}
.form-mail form div {
  margin-bottom: 2px;
}
.form-mail .form-mail-tit {
  font-size: 1.6em;
  font-weight: 700;
  text-align: center;
  color: #111;
  font-family: "AppleSDGothicNeo", "NotoSans", sans-serif;
  margin-bottom: 40px;
}
.form-mail .form-mail-tit span {
  display: block;
  font-size: 0.7em;
  font-weight: 600;
  margin-top: 10px;
}
.attendance_table .presence {
  background-color: #b2e2b0 !important;
  border: #b2e2b0;
}
.attendance_table .absence {
  background-color: rgb(255, 140, 140) !important;
  border: rgb(255, 140, 140);
}
.attendance_table .late {
  background-color: #777777 !important;
  border: #ccc;
}
.attendance_td {
  padding: 5px 10px;
  border-radius: 3px;
}
.course-start {
  position: fixed;
  bottom: 12%;
  right: 4.8%;
  background: #1b2a87;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  display: flex;
  color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.7s;
  font-weight: 700;
  z-index: 5;
}
.course-start span {
  display: block;
  font-weight: 700;
}
.course-start:after {
  /* display: block;
  content: "";
  width: 110%;
  height: 110%;
  position: absolute;
  left: 50%;
  top: 50%; */
}

.not_startable {
  background-color: #9b9b9b;
  pointer-events: none;
}

.course-start:hover {
  transition: all 0.7s;
  bottom: 13%;
  cursor: pointer;
}

.course-nav button.active {
  background-color: #1b2a87;
  color: #fff;
}

.rep-pop label.active {
  background-color: #1b2a87;
  color: #fff;
}
.header {
  position: fixed;
  display: flex;
  top: 0;
  justify-content: space-between;
  align-items: center;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 50px;
  padding-right: 50px;
  width: 100vw;
  z-index: 10;
}
.blue-logo {
  display: block;
  width: 120%;
}
.white-logo {
  display: block;
  width: 120%;
}

.logo {
  z-index: 13;
}

#click {
  display: none;
}
.center {
  text-align: center;
  font-weight: bold;
}
header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 10%;
  padding-right: 10%;
  width: 100vw;
}

/* nav */
.nav-links {
  list-style: none;
  position: absolute;
  left: 50%;
  top: 42%;
  transform: translateX(-50%);
  width: 60%;
  text-align: center;
}

.nav-links li {
  display: inline-block;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 20px;
  padding-right: 20px;
}

.nav-links li a {
  transition: all 0.3s ease 0s;
  color: #fff;
  font-size: 1.2em;
  display: block;
  position: relative;
  font-weight: 600;
}
.nav-links li .active {
  color: #3b89ff !important;
}
.nav-links:has(.active) ~ .nav-links {
  left: 100%;
}
.nav-links li a:after {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 0;
  height: 2px;
  background: #3b89ff;
  transition: width 0.45s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.nav-links li a:hover {
  color: #3b89ff !important;
}
.nav-links li a:hover:after,
.nav-links li a.active:after {
  width: 100%;
}
.menu-btn {
  color: white;
  display: none;
  cursor: pointer;
  position: absolute;
  top: 35px;
  right: 50px;
  width: 25px;
  height: 25px;
  z-index: 13;
}
.menu-btn i {
  width: 25px;
  height: 20px;
  display: block;
}
.menu-btn i::before {
  display: block;
  content: "";
  background: url("../assets/images/hamburger_open.png");
  width: 25px;
  height: 20px;
  transition: ease 0.3s;
}

.header.sticky .menu-btn i::before {
  background: url("../assets/images/hamburger_open_black.png");
}
.header.user-header .menu-btn i::before {
  background: url("../assets/images/hamburger_open_black.png");
}
.header.user-header.left-100 .menu-btn i::before {
  background: url("../assets/images/hamburger_open.png");
}
.header.user-header.left-100.sticky .menu-btn i::before {
  background: url("../assets/images/hamburger_open_black.png");
}

.nav-member {
  z-index: 13;
}
.nav-member a {
  color: #fff;
  padding-left: 20px;
  font-size: 1.2em;
}
.header.user-header.left-100 .nav-member a {
  color: #fff !important;
}
.header.sticky.user-header .nav-member a {
  color: #000 !important;
}

.header.sticky {
  background: #fff;
  border-bottom: 1px solid #3b57ff;
}
.header.sticky .nav li a {
  color: #111;
}
.header.sticky .nav li a::after {
  /* background: #111; */
}
.header.sticky .nav-member a {
  color: #111;
}

@media (max-width: 998px) {
  .header .nav-links {
    position: fixed;
    top: 0;
    padding-top: 100px;
    left: -100%;
    background-color: #24252a;
    height: 100vh;
    width: 100%;
    display: block;
    text-align: center;
    transition: all 0.3s ease;
    transform: none;
  }
  .header.left-100 .nav-links {
    left: 0;
  }

  .cta {
    display: inline-flex;
    position: fixed;
    bottom: 80px;
    width: 100vw;
    left: -100;
    justify-content: center;
    transition: all 0.3s ease;
  }

  #click:not(:checked) ~ .nav-links li a,
  #click:not(:checked) ~ .nav-links li a {
    color: #fff !important;
  }
  .header.sticky #click:not(:checked) ~ .nav-links li a,
  .header.sticky #click:not(:checked) ~ .nav-links li a {
    color: #000 !important;
  }

  #click:not(:checked) ~ .cta {
    left: -100%;
  }

  #click:checked ~ .cta {
    left: 0%;
  }
  #click:checked ~ .nav-links.nav_test li a {
    color: #fff !important;
  }
  #click:checked ~ .nav-links.nav_test + .nav-member a {
    color: #000 !important;
  }
  #click:checked ~ .nav-links.nav_test.left-100 + .nav-member a {
    color: #fff !important;
  }
  .header.user-header.left-100
    #click:checked
    ~ .nav-links.nav_test
    + .nav-member
    a {
    color: #fff !important;
  }
  .header.user-header.left-100.sticky
    #click:checked
    ~ .nav-links.nav_test
    + .nav-member
    a {
    color: #000 !important;
  }
  .sticky.header #click:checked ~ .nav-links.nav_test li a {
    color: #000 !important;
  }
  .sticky.header #click:checked ~ .nav-links.nav_test + .nav-member a {
    color: #000 !important;
  }
  .user-header {
  }

  #click:checked ~ .menu-btn i:before {
    /* content: "\f00d"; */
  }

  .nav-links li {
    display: block;
    margin-top: 40px;
  }

  .menu-btn {
    display: inline-block;
  }

  .header {
    padding-top: 2%;
    padding-bottom: 2%;
    padding-right: 25px;
    padding-left: 25px;
  }
  #click {
  }
  /* .header.left-100 #click:not(:checked) ~ .nav-links {
    left: 0;
  } */
  /* #click:checked ~ .nav-links {
    left: 0;
    z-index: 12;
  } */

  /* .header.left-100 #click:checked ~ .nav-links {
    left: 0;
    z-index: 12;
  }
  .header.left-100 #click:not(:checked) ~ .nav-links {
    left: -100%;
  } */

  /* #click:checked ~ .menu-btn i:before {
    content: "";
    background: url("../assets/images/hamburger_close.png");
    width: 25px;
    height: 20px;
    z-index: 13;
  } */

  /* .header.sticky #click:checked ~ .menu-btn i:before {
    content: "";
    background: url("../assets/images/hamburger_close_black.png");
    width: 25px;
    height: 20px;
    z-index: 13;
  } */
  .header.user-header.left-100.sticky #click:checked ~ .menu-btn i:before {
    content: "";
    background: url("../assets/images/hamburger_open_black.png");
    width: 25px;
    height: 20px;
    z-index: 13;
  }
  .header.sticky #click:not(:checked) ~ .nav-links {
    /* left: -100%; */
    top: 0;
    background-color: #fff;
  }
  .header.sticky #click:checked ~ .nav-links {
    top: 0;
    background-color: #fff;
  }
  .menu-btn {
    display: inline-block;
    right: 30px;
  }
  .nav-member a {
    padding-left: 10px;
  }
  .logo img {
    width: 100%;
  }
  .nav-member {
    display: block;
    position: absolute;
    top: 35px;
    right: 62px;
    z-index: 13;
  }
  .top-button {
    /* border-top-left-radius: 0;
    border-top-right-radius: 0; */
  }
}

.link_box .link_item {
  padding: 6px 16px;
  background: #eee;
  margin: 10px 10px 30px 10px;
  border-radius: 20px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0.9em;
}
.link_box .link_item:hover {
  /* font-weight: bold; */
  background: #ddd;
}
.login_popup {
  border: 1px solid black;
  border-radius: 5px;
  position: fixed;
}
.snb {
  position: fixed;
  top: 200px;
  left: 90%;
  z-index: 2;
  background-color: blue;
  color: #fff;
}
.container {
  max-width: 1440px;
  width: 100%;
  margin: 150px auto;
  padding: 0 20px;
}
.con-tit {
  font-size: 1.4em;
  color: #000;
  margin-bottom: 35px;
}

.footer {
  border-top: 1px solid #eeeeee;
  font-size: 1.1em;
}
.footer-container {
  max-width: 1440px;
  width: 100%;
  margin: 40px auto;
  padding: 0 20px;
  color: #1f1f1f;
}
.footer-container .flex {
  justify-content: space-between;
}
.footer-in span::after {
  display: inline-block;
  content: "|";
  margin: 0 8px;
}
.footer-in span:last-child::after {
  display: none;
}
.footer-container a {
  display: inline-block;
  padding-left: 10px;
}
.footer-container b {
  margin-bottom: 4px;
  color: #1f1f1f;
}
.footer-info {
  margin-bottom: 20px;
}

.footer-btn a {
  border: 1px solid #dbdbdb;
  background: #fff;
  padding: 0 7px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  margin-left: 10px;
  font-size: 0.85em;
}
/* .footer-container a:nth-of-type(1)::after,
.footer-container a:nth-of-type(2)::after {
  display: inline-block;
  content: "";
  width: 1px;
  height: 12px;
  background: #aaa;
  margin-left: 10px;
} */
.footer-container .footer-copy {
  padding-top: 20px;
  color: #555;
}
.join-personal-info {
  width: 100%;
  padding: 10px 10px;
  color: #333;
  font-weight: 600;
}

.personal-info {
  position: relative;
  width: 100%;
  padding: 8% 8%;
  color: #333;
  font-weight: 600;
}
.personal-info h1 {
  text-align: center;
  margin-bottom: 35px;
  color: #222;
}
.pri-box {
  margin-bottom: 20px;
}
.pri-box b {
  display: block;
  color: #000;
  margin-bottom: 5px;
}
.pri-box ol {
  padding-left: 15px;
}
.pri-box ol li {
  list-style: decimal;
  padding: 5px 0;
}
.pri-box ul li {
  padding: 5px 0;
  position: relative;
  padding-left: 18px;
}
ul.circle-num li::before {
  display: inline-block;
  padding-right: 5px;
  position: absolute;
  left: 0;
  top: 6px;
}
ul.circle-num li:nth-child(1)::before {
  content: "①";
}
ul.circle-num li:nth-child(2)::before {
  content: "②";
}
ul.circle-num li:nth-child(3)::before {
  content: "③";
}
ul.circle-num li:nth-child(4)::before {
  content: "④";
}
ul.circle-num li:nth-child(5)::before {
  content: "⑤";
}
ul.circle-num li:nth-child(6)::before {
  content: "⑥";
}
ul.circle-num li:nth-child(7)::before {
  content: "⑦";
}
ul.circle-num li:nth-child(8)::before {
  content: "⑧";
}
ul.circle-num li:nth-child(9)::before {
  content: "⑨";
}
.pri-box span {
  display: block;
  margin: 3px 0 5px 0;
}
.bar-txt span {
  display: block;
  color: #444;
  position: relative;
  padding-left: 10px;
}
.bar-txt span::before {
  display: inline-block;
  content: "-";
  margin-right: 4px;
  position: absolute;
  left: 0;
  top: -1px;
}
.pri-box ul li span:first-child,
.pri-box ol li span:first-child {
  margin-top: 10px;
}

.main-visual {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/main_visual.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease 0.8s;
}
.main-txt-wrap {
  color: #fff;
  margin-top: -15%;
  transition: ease 0.8s;
}
#arc-txt {
  display: block;
  width: 306px;
  height: 305px;
  position: relative;
  margin: 0 auto;
  transform: rotate(-39deg) translate(-105%, -15%);
  transition: ease 0.8s;
}
#arc-txt span {
  font-size: 26px;
  font-size: 2.8em;
  transform-origin: bottom;
  height: 400px;
  font-weight: 600;
  width: 50px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
#arc-txt .t0 {
  transform: rotate(0deg) translate(0, -2%);
}
#arc-txt .t1 {
  transform: rotate(3deg);
}
#arc-txt .t2 {
  transform: rotate(9deg);
}
#arc-txt .t3 {
  transform: rotate(11deg);
}
#arc-txt .t4 {
  transform: rotate(18deg);
}
#arc-txt .t5 {
  transform: rotate(24deg);
}
#arc-txt .t6 {
  transform: rotate(30deg);
}
#arc-txt .t7 {
  transform: rotate(36deg);
}
#arc-txt .t8 {
  transform: rotate(43deg);
}
#arc-txt .t9 {
  transform: rotate(46deg);
}
#arc-txt .t10 {
  transform: rotate(52deg);
}
#arc-txt .t11 {
  transform: rotate(54deg);
}
#arc-txt .t12 {
  transform: rotate(60deg);
}
#arc-txt .t13 {
  transform: rotate(66deg);
}
#arc-txt .t14 {
  transform: rotate(72deg);
}
#arc-txt .t15 {
  transform: rotate(78deg);
}
#arc-txt .t16 {
  transform: rotate(84deg) translate(0, -2%);
}

.main-txt {
  font-size: 2.4em;
  text-align: center;
  margin-top: 40px;
}
.main-txt span {
  display: block;
  background: #3b57ff;
  padding: 0 28px;
  font-size: 1.1em;
}
.greeting {
  padding-top: 40px;
}
.greeting .flex {
  align-items: center;
  justify-content: space-between;
}
.gr-img {
  width: 40%;
}
.gr-img img {
  width: 100%;
}
.gr-txt {
  width: 50%;
}
.gr-txt .set-tit-small {
  padding-bottom: 20px;
}
.gr-txt p {
  font-size: 1.5em;
  color: #000;
  word-break: keep-all;
  line-height: 1.6;
}
.gr-txt p:nth-of-type(2) {
  padding-bottom: 15px;
}
.advantages {
  position: relative;
  padding-top: 40px;
  overflow: hidden;
}
.advantages::before {
  display: block;
  content: "Our advantages";
  color: #eff1ff;
  position: absolute;
  font-size: 9vw;
  z-index: -1;
  font-weight: 900;
  top: 1%;
  left: -1%;
}
.advantages .flex {
  justify-content: space-between;
}
.ad-flow-wrap {
  width: 75%;
}
.ad-flow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.ad-flow p {
  text-align: center;
  color: #000;
  font-size: 1.5em;
  font-weight: 800;
  margin-top: 20px;
}
.flow {
  margin-bottom: 11%;
  display: block;
  position: relative;
  width: 44%;
}
.flow img {
  max-width: 100%;
  height: auto;
}
.flow i {
  display: block;
  position: absolute;
  background: url("../assets/images/flow_arrow.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 68px;
  height: 68px;
}
.ad-flow:nth-child(1) .flow:nth-child(1) i {
  right: -22%;
  top: 50%;
  transform: translate(0, -50%) scale(0.8);
}
.ad-flow:nth-child(1) .flow:nth-child(2) i {
  left: 50%;
  bottom: -22%;
  transform: translate(-50%, 0) rotate(90deg) scale(0.8);
}
.ad-flow:nth-child(2) .flow:nth-child(1) i {
  left: -16%;
  top: 40%;
  transform: translate(-40%, 0) rotate(-180deg) scale(0.8);
}
.ad-flow:nth-child(2) .flow:nth-child(2) i {
  left: 50%;
  top: -26%;
  transform: translate(-50%, 0) rotate(-90deg) scale(0.8);
}
.ad-flow:nth-child(2) {
  flex-direction: row-reverse;
}
.ad-flow:nth-child(2) .flow {
  margin-bottom: 0;
}

.cefr {
  position: relative;
  padding: 5px 0;
}
.cefr::before {
  display: block;
  content: "CEFR";
  color: #eff1ff;
  position: absolute;
  font-size: 9vw;
  z-index: -1;
  font-weight: 900;
  top: 8%;
  right: 10%;
}
.cefr-bg {
  background-image: url("../assets/images/cefr.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom center;
  padding: 8% 5%;
  color: #fff;
  font-size: 1.1em;
  text-align: center;
}
.cefr-bg p {
  line-height: 1.6;
}
.cefr-bg p:nth-of-type(1) {
  font-size: 2em;
  color: #cacaca;
  margin-bottom: 20px;
  font-weight: 600;
}
.cefr-bg p:nth-of-type(1) b {
  color: #fff;
  font-weight: 800;
}
.cefr-bg p:nth-of-type(2) {
  font-size: 1.4em;
}
.cefr-bg a {
  display: inline-block;
  background: #fff;
  padding: 15px 30px;
  border-radius: 30px;
  text-align: center;
  margin-top: 30px;
  color: #243dd8;
  font-weight: 800;
  font-size: 1.2em;
}

.sec-tit {
  font-size: 2.8em;
  color: #000;
  line-height: 1.4;
  margin-bottom: 40px;
}
.set-tit-small {
  font-size: 0.8em;
}
.flex {
  display: flex;
}
.colorBlue {
  color: #3b57ff;
}
.colorRed {
  color: #cd0000 !important;
}
.block {
  display: block;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 0;
}

.quick_menu_wrap {
  position: fixed;
  top: 50%;
  right: 5%;
  z-index: 2;
}
.quick_menu_wrap label {
  display: block;
  overflow: hidden;
}
.quick_btn {
  display: block;
  position: fixed;

  /* right:9.7%;
  top:50%;
  transform:translate(0,-50%);
  transition: ease 1s; */
  position: fixed;
  right: 5%;
  bottom: 35px;
  width: 80px;
  text-align: center;
  transition: ease 1s;
}
.quick_btn::before {
  display: block;
  content: "MENU OPEN";
  background-color: #3c6fff;
  color: #fff;
  padding: 10px 10px;
  font-weight: 600;
  font-size: 0.8em;
  border-radius: 5px;
  /* writing-mode: vertical-lr;

  text-orientation: upright; */
}
.quick_btn:hover {
  cursor: pointer;
}

.quick_menu_wrap #snb {
  position: fixed;
  padding: 0;
  right: -25%;
  transition: ease 1s;
}
.quick_menu_wrap input {
  display: block;
  width: 38px;
}
.quick_menu_wrap input[type="checkbox"] {
  display: none;
}
.quick_menu_wrap input[type="checkbox"]:checked + #snb + .quick_btn {
  /* right:0; */
}
.quick_menu_wrap input[type="checkbox"]:checked + #snb + .quick_btn::before {
  content: "MENU CLOSE";
}

.quick_menu_wrap input[type="checkbox"]:checked + #snb {
  right: 5%;
}

#snb {
  position: fixed;
  top: 40%;
  right: 5%;
  transform: translate(0, -40%);
  z-index: 2;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

#snb nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}
#snb nav > ul {
  padding: 0 15px;
  background-color: #fff;
  border-radius: 20px;
}

#snb nav > ul > li {
  border-bottom: 1px solid #ddd;
  padding: 10px 0 10px 0;
}
#snb nav ul li {
  /* display:inline-block; */
  text-align: center;
}
#snb nav > ul > li:nth-child(1) {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
#snb nav > ul > li:nth-child(1) a {
  color: #ff6815;
  font-weight: 600;
}
#snb nav > ul > li:nth-child(2) > a {
  color: #3b57ff;
  font-weight: 600;
}

#snb nav > ul > li:last-child {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom: 0;
}

#snb nav ul li a {
  font-size: 0.9em;
  color: #111;
}
#snb nav ul li a img {
  display: block;
  margin: 10px auto;
}

#snb nav a {
  display: block;
}
#snb nav ul > li {
  position: relative;
}
#snb nav ul ul {
  display: none;
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translate(0, -50%);
  padding-right: 20px;
}

#snb nav ul li:hover > ul {
  display: flex;
}
#snb nav ul li ul li:hover {
  border: 1px solid #3b57ff;
}

#snb nav ul li ul li {
  width: 80px;
  height: 80px;
  display: inline-block;
  background-color: #fff;
  border-radius: 40px;
  text-align: center;
  padding: 10px 0;
  margin-right: 10px;
  transition: all 0.6s ease 0s;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}
#snb nav ul li ul li img {
  margin: 5px auto;
}
@media (max-width: 1600px) {
  .modal {
    width: 80%;
  }
}
@media (max-width: 1200px) {
  .sec-tit {
    font-size: 2.2em;
  }
  .gr-txt p {
    font-size: 1.3em;
  }
  .pop-cefr .cefr-table {
    width: 100%;
    overflow-x: scroll;
  }
  .pop-cefr table {
    width: 1000px;
  }
}
@media (max-width: 998px) {
  .main-pop:nth-of-type(1) .main-pop-wrap,
  .main-pop:nth-of-type(2) .main-pop-wrap,
  .main-pop:nth-of-type(3) .main-pop-wrap,
  .main-pop:nth-of-type(4) .main-pop-wrap,
  .main-pop:nth-of-type(5) .main-pop-wrap {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .menu-btn {
    top: 40px;
  }
  .nav-member {
    top: 40px;
  }
  .container {
    margin: 110px auto;
  }
  .con-tit {
    font-size: 1.2em;
  }
  .sec-tit {
    font-size: 2em;
  }
  .main-visual {
    width: 100%;
    height: 100vh;
    background-image: url("../assets/images/main_visual_m.png");
  }
  #arc-txt {
    transform: rotate(-39deg) translate(-65%, -19%) scale(0.7);
  }
  .main-txt {
    font-size: 1.8em;
    margin-top: -20px;
  }
  .quick_btn {
    position: fixed;
    left: auto;
    top: auto;
    right: 5%;
    bottom: 35px;
    transform: none;
    width: 80px;
    text-align: center;
  }
  .quick_btn::before {
    display: block;
    content: "MENU";
    background-color: #3c6fff;
    color: #fff;
    padding: 10px 10px;
    font-weight: 600;
    font-size: 0.8em;
    writing-mode: unset;
    text-orientation: unset;
    transition: ease 0.3s;
  }
  .quick_btn:hover {
    cursor: pointer;
  }
  .quick_menu_wrap #snb {
    position: fixed;
    padding: 0;
    right: -20%;
    transition: ease 1s;
  }
  .quick_menu_wrap input {
    display: block;
    width: 38px;
  }
  .quick_menu_wrap input[type="checkbox"] {
    display: none;
  }
  .quick_menu_wrap input[type="checkbox"]:checked + #snb + .quick_btn {
    left: auto;
  }
  .quick_menu_wrap input[type="checkbox"]:checked + #snb + .quick_btn::before {
    content: "CLOSE";
  }
  .quick_menu_wrap input[type="checkbox"]:checked + #snb {
    right: 5%;
    display: block;
  }
  #snb nav ul li ul li {
    height: auto;
    margin-right: 5px;
    width: 75px;
  }
  #snb nav ul li a img {
    display: none;
  }
  #snb nav > ul > li {
    padding: 15px 0 15px 0;
  }
  .greeting .flex {
    flex-direction: column;
  }
  .gr-img {
    width: 45%;
    margin-bottom: 80px;
  }
  .gr-txt {
    width: 100%;
    text-align: center;
  }
  .advantages {
    text-align: center;
    padding-top: 0;
  }
  .advantages .sec-tit span {
    display: inline-block;
    margin: 0 3px;
  }
  .advantages::before {
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }
  .advantages .flex {
    flex-direction: column;
  }
  .ad-flow-wrap {
    width: 70%;
    margin: 0 auto;
  }
  .ad-flow:nth-child(1) .flow:nth-child(1) i {
    right: -26%;
    transform: translate(0, -50%) scale(0.5);
  }
  .ad-flow:nth-child(1) .flow:nth-child(2) i {
    bottom: -25%;
    transform: translate(-50%, 0) rotate(90deg) scale(0.5);
  }
  .ad-flow:nth-child(2) .flow:nth-child(1) i {
    transform: translate(-40%, 0) rotate(-180deg) scale(0.5);
  }
  .ad-flow:nth-child(2) .flow:nth-child(2) i {
    top: -30%;
    transform: translate(-50%, 0) rotate(-90deg) scale(0.5);
  }
  .cefr {
    text-align: center;
  }
  .cefr::before {
    top: 20px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
  .cefr-bg p:nth-of-type(1) {
    font-size: 1.5em;
  }
  .cefr-bg p:nth-of-type(2) {
    font-size: 1em;
  }
  .cefr-bg a {
    font-size: 1.1em;
  }
  .pop-cefr h1 {
    font-size: 1.1em;
  }
  .pop-cefr .cefr-table {
    width: 100%;
    overflow-x: scroll;
  }

  .footer {
    font-size: 0.95em;
  }
}

@media (max-width: 768px) {
  .menu-btn {
    top: 30px;
    right: 25px;
  }
  .nav-member {
    top: 32px;
  }
  .logo img {
    width: 60%;
    padding-top: 10px;
    padding-bottom: 5px;
  }
  .nav-member a {
    font-size: 1em;
  }
  .menu-btn i::before {
    transform: scale(0.8);
  }
  .container {
    margin: 80px auto;
  }
  .main-visual {
    width: 100%;
    height: 80vh;
  }
  .main-txt {
    margin-top: -40%;
    transform: scale(0.7);
  }
  .main-txt span {
    font-size: 1em;
  }
  #arc-txt {
    transform: rotate(-39deg) translate(-42%, -19%) scale(0.5);
  }
  .quick_menu_wrap #snb {
    right: -25%;
  }
  #snb nav ul li a {
    font-size: 0.85em;
  }
  .quick_btn::before {
    font-size: 0.9em;
  }
  .sec-tit {
    font-size: 1.4em;
    margin-bottom: 20px;
  }
  .greeting {
    padding-top: 0;
  }
  .gr-img {
    margin-bottom: 40px;
    width: 60%;
  }
  .gr-txt p {
    font-size: 1em;
  }
  .gr-txt .set-tit-small {
    padding-bottom: 10px;
  }
  .ad-flow-wrap {
    width: 95%;
  }
  .ad-flow p {
    font-size: 1.1em;
    margin-top: 10px;
  }

  .ad-flow:nth-child(1) .flow:nth-child(1) i {
    transform: translate(0, -50%) scale(0.3);
  }
  .ad-flow:nth-child(1) .flow:nth-child(2) i {
    transform: translate(-50%, 0) rotate(90deg) scale(0.3);
  }
  .ad-flow:nth-child(2) .flow:nth-child(1) i {
    transform: translate(-40%, 0) rotate(-180deg) scale(0.3);
  }
  .ad-flow:nth-child(2) .flow:nth-child(2) i {
    transform: translate(-50%, 0) rotate(-90deg) scale(0.3);
  }
  .cefr-bg {
    background-size: cover;
  }
  .cefr-bg p:nth-of-type(1) {
    font-size: 1.2em;
  }
  .cefr-bg a {
    font-size: 1em;
  }
  .pop-cefr h1 {
    font-size: 1em;
  }
  .pop-cefr table tr td,
  .pop-cefr table tr th {
    padding: 10px 4px;
    font-size: 0.9em;
  }
  .close-btn {
    width: 20px;
    height: 20px;
  }
  .close-btn::before,
  .close-btn::after {
    height: 20px;
  }

  .footer-container .flex {
    flex-direction: column;
  }
  .footer-container b {
    margin: 0 auto;
    text-align: center;
    font-size: 1.3em;
  }
  .footer-btn {
    margin: 20px auto;
  }
  .footer-in {
    margin-top: 0;
    text-align: center;
  }
  .footer-in span {
    display: block;
    padding-bottom: 4px;
  }
  .footer-in span::after {
    display: none;
  }
  .footer-copy {
    text-align: center;
  }
  .footer-btn a {
    margin-left: 0;
    margin: 0 2px;
  }
  .close-btn {
    top: 25px;
    right: 20px;
  }

  .personal-info h1 {
    font-size: 1.1em;
  }
}

@media (max-width: 600px) {
  .ad-flow:nth-child(1) .flow:nth-child(1) i {
    right: -37%;
    top: 40%;
  }
  .ad-flow:nth-child(1) .flow:nth-child(2) i {
    bottom: -32%;
  }
  .ad-flow:nth-child(2) .flow:nth-child(1) i {
    left: -18%;
    top: 20%;
  }
  .ad-flow:nth-child(2) .flow:nth-child(2) i {
    top: -40%;
  }
  .cefr-bg p:nth-of-type(2) br {
    display: none;
  }
}

/* ||==========MainLayout==========|| */

/* wow */
.animated {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}
.animated.bounceIn,
.animated.bounceOut,
.animated.flipOutX,
.animated.flipOutY {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
}
@-webkit-keyframes bounce {
  0%,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  40%,
  43% {
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  40%,
  43%,
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  70% {
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}
@keyframes bounce {
  0%,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  40%,
  43% {
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  40%,
  43%,
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  70% {
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}
.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}
@-webkit-keyframes flash {
  0%,
  50%,
  to {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes flash {
  0%,
  50%,
  to {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
.flash {
  -webkit-animation-name: flash;
  animation-name: flash;
}

@-webkit-keyframes flash2 {
  0%,
  50%,
  to {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes flash2 {
  0%,
  50%,
  to {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
.flash2 {
  -webkit-animation-name: flash2;
  animation-name: flash2;
}

@-webkit-keyframes flash3 {
  0%,
  50%,
  to {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes flash3 {
  0%,
  50%,
  to {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
.flash3 {
  -webkit-animation-name: flash3;
  animation-name: flash3;
}

@-webkit-keyframes flash4 {
  0%,
  50%,
  to {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes flash4 {
  0%,
  50%,
  to {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
.flash4 {
  -webkit-animation-name: flash4;
  animation-name: flash4;
}

.animated.flash {
  animation-duration: 6s;
  animation-timing-function: ease;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-direction: alternate;
}

.animated.flash2 {
  animation-duration: 6s;
  animation-timing-function: ease;
  animation-delay: 1.5s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-direction: alternate;
}

.animated.flash3 {
  animation-duration: 6s;
  animation-timing-function: ease;
  animation-delay: 2.5s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-direction: alternate;
}
.animated.flash4 {
  animation-duration: 6s;
  animation-timing-function: ease;
  animation-delay: 3.5s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-direction: alternate;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}
@-webkit-keyframes rubberBand {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
@keyframes rubberBand {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}
@-webkit-keyframes shake {
  0%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
@keyframes shake {
  0%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
.shake {
  -webkit-animation-name: shake;
  animation-name: shake;
}
@-webkit-keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.headShake {
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: headShake;
  animation-name: headShake;
}
@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes swing {
  20% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
}
@-webkit-keyframes tada {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
@keyframes tada {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
.tada {
  -webkit-animation-name: tada;
  animation-name: tada;
}
@-webkit-keyframes wobble {
  0% {
    -webkit-transform: none;
    transform: none;
  }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate(-5deg);
    transform: translate3d(-25%, 0, 0) rotate(-5deg);
  }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate(3deg);
    transform: translate3d(20%, 0, 0) rotate(3deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate(-3deg);
    transform: translate3d(-15%, 0, 0) rotate(-3deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate(2deg);
    transform: translate3d(10%, 0, 0) rotate(2deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate(-1deg);
    transform: translate3d(-5%, 0, 0) rotate(-1deg);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes wobble {
  0% {
    -webkit-transform: none;
    transform: none;
  }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate(-5deg);
    transform: translate3d(-25%, 0, 0) rotate(-5deg);
  }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate(3deg);
    transform: translate3d(20%, 0, 0) rotate(3deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate(-3deg);
    transform: translate3d(-15%, 0, 0) rotate(-3deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate(2deg);
    transform: translate3d(10%, 0, 0) rotate(2deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate(-1deg);
    transform: translate3d(-5%, 0, 0) rotate(-1deg);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble;
}
@-webkit-keyframes jello {
  0%,
  11.1%,
  to {
    -webkit-transform: none;
    transform: none;
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}
@keyframes jello {
  0%,
  11.1%,
  to {
    -webkit-transform: none;
    transform: none;
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}
.jello {
  -webkit-animation-name: jello;
  animation-name: jello;
  -webkit-transform-origin: center;
  transform-origin: center;
}
@-webkit-keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
@keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}
@-webkit-keyframes bounceInDown {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bounceInDown {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}
@-webkit-keyframes bounceInLeft {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bounceInLeft {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}
@-webkit-keyframes bounceInRight {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bounceInRight {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
}
@-webkit-keyframes bounceInUp {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
@keyframes bounceInUp {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
}
@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
.bounceOut {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
}
@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown;
}
@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft;
}
@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight;
}
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -ms-transform: translateY(40px);
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -ms-transform: translateY(40px);
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes fadeOutDown {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}
@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig;
}
@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes fadeOutLeft {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}
@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig;
}
@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes fadeOutRight {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}
@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig;
}
@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@keyframes fadeOutUp {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}
@-webkit-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig;
}
@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(-1turn);
    transform: perspective(400px) rotateY(-1turn);
  }
  0%,
  40% {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(-190deg);
    transform: perspective(400px) translateZ(150px) rotateY(-190deg);
  }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(-170deg);
    transform: perspective(400px) translateZ(150px) rotateY(-170deg);
  }
  50%,
  80% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
@keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(-1turn);
    transform: perspective(400px) rotateY(-1turn);
  }
  0%,
  40% {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(-190deg);
    transform: perspective(400px) translateZ(150px) rotateY(-190deg);
  }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(-170deg);
    transform: perspective(400px) translateZ(150px) rotateY(-170deg);
  }
  50%,
  80% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip;
}
@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
  0%,
  40% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-20deg);
    transform: perspective(400px) rotateX(-20deg);
  }
  60% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotateX(-5deg);
    transform: perspective(400px) rotateX(-5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
  0%,
  40% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-20deg);
    transform: perspective(400px) rotateX(-20deg);
  }
  60% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotateX(-5deg);
    transform: perspective(400px) rotateX(-5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
}
@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
  0%,
  40% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  40% {
    -webkit-transform: perspective(400px) rotateY(-20deg);
    transform: perspective(400px) rotateY(-20deg);
  }
  60% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotateY(-5deg);
    transform: perspective(400px) rotateY(-5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
  0%,
  40% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  40% {
    -webkit-transform: perspective(400px) rotateY(-20deg);
    transform: perspective(400px) rotateY(-20deg);
  }
  60% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotateY(-5deg);
    transform: perspective(400px) rotateY(-5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
.flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
}
@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotateX(-20deg);
    transform: perspective(400px) rotateX(-20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}
@keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotateX(-20deg);
    transform: perspective(400px) rotateX(-20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}
.flipOutX {
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}
@-webkit-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotateY(-15deg);
    transform: perspective(400px) rotateY(-15deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}
@keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotateY(-15deg);
    transform: perspective(400px) rotateY(-15deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}
.flipOutY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY;
}
@-webkit-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
  }
  60%,
  80% {
    opacity: 1;
  }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
  }
  to {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes lightSpeedIn {
  0% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
  }
  60%,
  80% {
    opacity: 1;
  }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
  }
  to {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}
@-webkit-keyframes lightSpeedOut {
  0% {
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}
@keyframes lightSpeedOut {
  0% {
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}
.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}
@-webkit-keyframes rotateIn {
  0% {
    transform-origin: center;
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0;
  }
  0%,
  to {
    -webkit-transform-origin: center;
  }
  to {
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes rotateIn {
  0% {
    transform-origin: center;
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0;
  }
  0%,
  to {
    -webkit-transform-origin: center;
  }
  to {
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn;
}
@-webkit-keyframes rotateInDownLeft {
  0% {
    transform-origin: left bottom;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0;
  }
  0%,
  to {
    -webkit-transform-origin: left bottom;
  }
  to {
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes rotateInDownLeft {
  0% {
    transform-origin: left bottom;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0;
  }
  0%,
  to {
    -webkit-transform-origin: left bottom;
  }
  to {
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft;
}
@-webkit-keyframes rotateInDownRight {
  0% {
    transform-origin: right bottom;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }
  0%,
  to {
    -webkit-transform-origin: right bottom;
  }
  to {
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes rotateInDownRight {
  0% {
    transform-origin: right bottom;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }
  0%,
  to {
    -webkit-transform-origin: right bottom;
  }
  to {
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight;
}
@-webkit-keyframes rotateInUpLeft {
  0% {
    transform-origin: left bottom;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }
  0%,
  to {
    -webkit-transform-origin: left bottom;
  }
  to {
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes rotateInUpLeft {
  0% {
    transform-origin: left bottom;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }
  0%,
  to {
    -webkit-transform-origin: left bottom;
  }
  to {
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft;
}
@-webkit-keyframes rotateInUpRight {
  0% {
    transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
  0%,
  to {
    -webkit-transform-origin: right bottom;
  }
  to {
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes rotateInUpRight {
  0% {
    transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
  0%,
  to {
    -webkit-transform-origin: right bottom;
  }
  to {
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight;
}
@-webkit-keyframes rotateOut {
  0% {
    transform-origin: center;
    opacity: 1;
  }
  0%,
  to {
    -webkit-transform-origin: center;
  }
  to {
    transform-origin: center;
    -webkit-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0;
  }
}
@keyframes rotateOut {
  0% {
    transform-origin: center;
    opacity: 1;
  }
  0%,
  to {
    -webkit-transform-origin: center;
  }
  to {
    transform-origin: center;
    -webkit-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0;
  }
}
.rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut;
}
@-webkit-keyframes rotateOutDownLeft {
  0% {
    transform-origin: left bottom;
    opacity: 1;
  }
  0%,
  to {
    -webkit-transform-origin: left bottom;
  }
  to {
    transform-origin: left bottom;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }
}
@keyframes rotateOutDownLeft {
  0% {
    transform-origin: left bottom;
    opacity: 1;
  }
  0%,
  to {
    -webkit-transform-origin: left bottom;
  }
  to {
    transform-origin: left bottom;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }
}
.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft;
}
@-webkit-keyframes rotateOutDownRight {
  0% {
    transform-origin: right bottom;
    opacity: 1;
  }
  0%,
  to {
    -webkit-transform-origin: right bottom;
  }
  to {
    transform-origin: right bottom;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0;
  }
}
@keyframes rotateOutDownRight {
  0% {
    transform-origin: right bottom;
    opacity: 1;
  }
  0%,
  to {
    -webkit-transform-origin: right bottom;
  }
  to {
    transform-origin: right bottom;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0;
  }
}
.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight;
}
@-webkit-keyframes rotateOutUpLeft {
  0% {
    transform-origin: left bottom;
    opacity: 1;
  }
  0%,
  to {
    -webkit-transform-origin: left bottom;
  }
  to {
    transform-origin: left bottom;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0;
  }
}
@keyframes rotateOutUpLeft {
  0% {
    transform-origin: left bottom;
    opacity: 1;
  }
  0%,
  to {
    -webkit-transform-origin: left bottom;
  }
  to {
    transform-origin: left bottom;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0;
  }
}
.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft;
}
@-webkit-keyframes rotateOutUpRight {
  0% {
    transform-origin: right bottom;
    opacity: 1;
  }
  0%,
  to {
    -webkit-transform-origin: right bottom;
  }
  to {
    transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}
@keyframes rotateOutUpRight {
  0% {
    transform-origin: right bottom;
    opacity: 1;
  }
  0%,
  to {
    -webkit-transform-origin: right bottom;
  }
  to {
    transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}
.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight;
}
@-webkit-keyframes hinge {
  0% {
    transform-origin: top left;
  }
  0%,
  20%,
  60% {
    -webkit-transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate(80deg);
    transform: rotate(80deg);
    transform-origin: top left;
  }
  40%,
  80% {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
@keyframes hinge {
  0% {
    transform-origin: top left;
  }
  0%,
  20%,
  60% {
    -webkit-transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate(80deg);
    transform: rotate(80deg);
    transform-origin: top left;
  }
  40%,
  80% {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
.hinge {
  -webkit-animation-name: hinge;
  animation-name: hinge;
}
@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate(-120deg);
    transform: translate3d(-100%, 0, 0) rotate(-120deg);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate(-120deg);
    transform: translate3d(-100%, 0, 0) rotate(-120deg);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn;
}
@-webkit-keyframes rollOut {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate(120deg);
    transform: translate3d(100%, 0, 0) rotate(120deg);
  }
}
@keyframes rollOut {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate(120deg);
    transform: translate3d(100%, 0, 0) rotate(120deg);
  }
}
.rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut;
}
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}
@-webkit-keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown;
}
@-webkit-keyframes zoomInLeft {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInLeft {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft;
}
@-webkit-keyframes zoomInRight {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInRight {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight;
}
@-webkit-keyframes zoomInUp {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInUp {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp;
}
@-webkit-keyframes zoomOut {
  0% {
    opacity: 1;
  }
  50% {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50%,
  to {
    opacity: 0;
  }
}
@keyframes zoomOut {
  0% {
    opacity: 1;
  }
  50% {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50%,
  to {
    opacity: 0;
  }
}
.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
}
@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown;
}
@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}
@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}
.zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft;
}
@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}
@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}
.zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight;
}
@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp;
}
@-webkit-keyframes slideInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
@keyframes slideInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}
@-webkit-keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
@keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}
@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
@keyframes slideInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}
@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
@keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}
@-webkit-keyframes slideOutDown {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes slideOutDown {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
}
@-webkit-keyframes slideOutLeft {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes slideOutLeft {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}
@-webkit-keyframes slideOutRight {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes slideOutRight {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}
@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@keyframes slideOutUp {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
}
