.teach {
  color: #3b57ff;
  font-weight: bold;
}
.sub-top {
  width: 100%;
  height: 100vh;
  transition: ease 0.8s;
}
.sub-bg {
  display: flex;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
}
.sub-top-txt {
  padding: 0 5%;
  color: #fff;
  font-size: 5.2em;
  font-family: "NotoSans", sans-serif;
  font-weight: 900;
  line-height: 1.1;
  text-transform: uppercase;
  text-align: center;
}
.sub-top-txt span {
  font-size: 0.44em;
  font-weight: 600;
  margin-top: 40px;
  line-height: 1.4;
}

/* 강사소개 */
.teacher {
  width: 100%;
  background-image: url("../assets/images/teacher_info.jpg");
}
.teacher-wrap {
  width: 100%;
  /* display: flex; */
  position: relative;
}
.teacher-profile {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.teacher-name {
  font-size: 1.8em;
  margin-left: 30px;
}
.teacher-word-bttom {
  padding: 20px 20px;
  font-size: 1.1em;
}
.country {
  font-size: 0.9em;
  display: block;
}
.teacher-pic {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}
.teacher-pic img {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
}
.teacher-page {
  width: 100%;
  padding: 8% 10%;
  color: #fff;
  position: relative;
}
.swiper-slide:nth-child(odd) .teacher-page {
  background: #1c2665;
}
.swiper-slide:nth-child(even) .teacher-page {
  background: #2d3982;
}
.teacher-page::before {
  display: block;
  content: "";
  position: absolute;
  left: 8%;
  top: 32%;
  background-image: url("../assets/images/teacher-word-left.png");
  background-repeat: no-repeat;
  background-size: contain;
  color: #6f79ba;
  width: 94px;
  height: 83px;
}
.teacher-page::after {
  display: block;
  content: "";
  position: absolute;
  right: 8%;
  bottom: 10%;
  background-image: url("../assets/images/teacher-word-right.png");
  background-repeat: no-repeat;
  background-size: contain;
  color: #6f79ba;
  width: 94px;
  height: 83px;
}
.teacher-word {
  padding: 40px 0 0 0;
  min-height: 500px;
}
.teacher-word-top {
  white-space: pre-wrap;
  font-size: 3em;
  /* font-family: "NotoSans", sans-serif; */
  font-weight: 900;
  padding: 15px 20px;
  position: relative;
  display: grid;
  min-height: 195px;
}
.teacher-word-top::after {
  display: block;
  content: "";
  border-bottom: 1px solid #fff;
  padding-bottom: 30px;
}
.teacher-word-bottom p {
  font-size: 1.5em;
  padding: 30px 0;
  white-space: pre-wrap;
}
/* .swiper-button-prev,
.swiper-button-next {
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 999999999;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  transition: opacity 0.2s ease-out;
} */
.swiper-button-prev,
.swiper-button-next {
  width: 61px;
  height: 118px;
  z-index: 1;
  cursor: pointer;
  display: block;
  color: #fff;
  opacity: 0.8;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  opacity: 1;
}

.swiper-button-prev {
  position: absolute;
  top: 50%;
  left: 0;
  background-image: url("../assets/images/swiper_arrow.png");
  background-repeat: no-repeat;
  transform: translateY(-50%) rotate(180deg);
}

.swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  background-image: url("../assets/images/swiper_arrow.png");
  background-repeat: no-repeat;
}

/* 수업소개 */
.class {
  width: 100%;
  background-image: url("../assets/images/class.png");
}
.solution-wrap {
  background: url("../assets/images/solution_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 100px 0;
  color: #fff;
}
.solution-wrap h1 {
  color: #fff;
}

ol.solution-chart,
ol.solution-chart ol,
ol.solution-chart li,
ol.solution-chart li > div {
  position: relative;
}
ol.solution-chart,
ol.solution-chart ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
ol.solution-chart {
  text-align: center;
}
ol.solution-chart ol {
  padding-top: 1em;
}
ol.solution-chart ol:before,
ol.solution-chart ol:after,
ol.solution-chart li:before,
ol.solution-chart li:after,
ol.solution-chart > li > div:before,
ol.solution-chart > li > div:after {
  display: none;
}
ol.solution-chart ol > li {
  width: 100%;
  padding: 0 10%;
}
ol.solution-chart > li ol:before {
  height: 1em;
  left: 50%;
  top: 0;
  width: 3px;
}
ol.solution-chart > li ol:after {
  height: 3px;
  left: 3px;
  top: 1em;
  width: 50%;
}
ol.solution-chart > li ol > li:not(:last-of-type):before {
  height: 3px;
  left: 0;
  top: 2em;
  width: 1em;
}
ol.solution-chart > li ol > li:not(:last-of-type):after {
  height: 100%;
  left: 0;
  top: 0;
  width: 3px;
}
ol.solution-chart > li ol > li:last-of-type:before {
  height: 3px;
  left: 0;
  top: 2em;
  width: 1em;
}
ol.solution-chart > li ol > li:last-of-type:after {
  height: 2em;
  left: 0;
  top: 0;
  width: 3px;
}
ol.solution-chart > li > div {
  margin-right: 1em;
}
ol.solution-chart > li > div:before {
  bottom: 2em;
  height: 3px;
  right: -1em;
  width: 1em;
}
ol.solution-chart > li > div:first-of-type:after {
  bottom: 0;
  height: 2em;
  right: -1em;
  width: 3px;
}
ol.solution-chart > li > div + div {
  margin-top: 1em;
}
ol.solution-chart > li > div + div:after {
  height: calc(100% + 1em);
  right: -1em;
  top: -1em;
  width: 3px;
}

ol.solution-chart > li > ol:before {
  left: inherit;
  right: 0;
}
ol.solution-chart > li > ol:after {
  left: 0;
  width: 100%;
}
ol.solution-chart > li > ol > li > div h2 {
  font-size: 2em;
  margin-top: 20px;
  font-family: "NotoSans", sans-serif;
  font-weight: 900;
}
ol.solution-chart > li > ol > li > p {
  display: block;
  border: 3px solid #fff;
  border-radius: 30px;
  display: flex;
  height: 100px;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}
ol.solution-chart > li > ol > li > p span {
  display: block;
  text-align: center;
  padding-bottom: 8px;
}
ol.solution-chart > li > ol > li > p span:last-child {
  padding-bottom: 0;
}

@media (min-width: 768px) {
  ol.solution-chart {
    margin-left: -1em;
    margin-right: -1em;
  }
  ol.solution-chart ol > li {
    width: 31%;
  }
  ol.solution-chart > li > div {
    display: inline-block;
    float: none;
    margin: 0 1em 1em 1em;
    vertical-align: bottom;
  }
  ol.solution-chart > li > ol > li > p {
    display: block;
    padding: 2em 2em;
    text-align: center;
    font-size: 1.8em;
    border: 3px solid #fff;
    border-radius: 30px;
    display: flex;
    height: 300px;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
  }
  ol.solution-chart ol:before,
  ol.solution-chart ol:after,
  ol.solution-chart li:before,
  ol.solution-chart li:after,
  ol.solution-chart > li > div:before,
  ol.solution-chart > li > div:after {
    background-color: #b7a6aa;
    content: "";
    position: absolute;
    display: block;
  }
  ol.solution-chart > li > div:only-of-type {
    margin-bottom: 0;
    width: calc((100% / 1) - 2em - 4px);
  }
  ol.solution-chart > li > div:first-of-type:nth-last-of-type(2),
  ol.solution-chart > li > div:first-of-type:nth-last-of-type(2) ~ div {
    width: calc((100% / 2) - 2em - 4px);
  }
  ol.solution-chart > li > div:first-of-type:nth-last-of-type(3),
  ol.solution-chart > li > div:first-of-type:nth-last-of-type(3) ~ div {
    width: calc((100% / 3) - 2em - 4px);
  }

  ol.solution-chart > li > div:before,
  ol.solution-chart > li > div:after {
    bottom: -1em !important;
    top: inherit !important;
  }
  ol.solution-chart > li > div:before {
    height: 1em !important;
    left: 50% !important;
    width: 3px !important;
  }
  ol.solution-chart > li > div:only-of-type:after {
    display: none;
  }
  ol.solution-chart > li > div:first-of-type:not(:only-of-type):after,
  ol.solution-chart > li > div:last-of-type:not(:only-of-type):after {
    bottom: -1em;
    height: 3px;
    width: calc(50% + 1em + 3px);
  }
  ol.solution-chart > li > div:first-of-type:not(:only-of-type):after {
    left: calc(50% + 3px);
  }
  ol.solution-chart > li > div:last-of-type:not(:only-of-type):after {
    left: calc(-1em - 3px);
  }
  ol.solution-chart > li > div + div:not(:last-of-type):after {
    height: 3px;
    left: -2em;
    width: calc(100% + 4em);
  }

  ol.solution-chart > li > ol {
    display: flex;
    flex-wrap: nowrap;
  }
  ol.solution-chart > li > ol:before,
  ol.solution-chart > li > ol > li:before {
    height: 1em !important;
    left: 50% !important;
    top: 0 !important;
    width: 3px !important;
  }
  ol.solution-chart > li > ol:after {
    display: none;
  }
  ol.solution-chart > li > ol > li {
    flex-grow: 1;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 1em;
  }
  ol.solution-chart > li > ol > li:only-of-type {
    padding-top: 0;
  }
  ol.solution-chart > li > ol > li:only-of-type:before,
  ol.solution-chart > li > ol > li:only-of-type:after {
    display: none;
  }
  ol.solution-chart > li > ol > li:first-of-type:not(:only-of-type):after,
  ol.solution-chart > li > ol > li:last-of-type:not(:only-of-type):after {
    height: 3px;
    top: 0;
    width: 50%;
  }
  ol.solution-chart > li > ol > li:first-of-type:not(:only-of-type):after {
    left: 50%;
  }
  ol.solution-chart > li > ol > li:last-of-type:not(:only-of-type):after {
    left: 0;
  }
  ol.solution-chart > li > ol > li + li:not(:last-of-type):after {
    height: 3px;
    left: 0;
    top: 0;
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .solution-chart {
    transition: ease 0.8s;
  }
  .solution-chart .con-tit {
    font-size: 1.2em;
  }
  ol.solution-chart > li > ol > li > div h2 {
    font-size: 1.8em;
  }
  ol.solution-chart > li > ol > li > p {
    font-size: 1.5em;
    height: 250px;
  }
}
@media (max-width: 998px) {
  .solution-chart .con-tit {
    font-size: 1em;
    margin-right: 0;
  }
  ol.solution-chart > li > ol > li > div h2 {
    font-size: 1.3em;
  }
  ol.solution-chart ol > li {
    margin-bottom: 30px;
  }
  ol.solution-chart > li > ol > li > p {
    font-size: 1.2em;
    height: 200px;
  }
}
@media (max-width: 768px) {
  .solution-chart .con-tit {
    font-size: 0.9em;
    margin-bottom: 0;
  }
  ol.solution-chart > li > ol > li > p {
    margin-top: 10px;
  }
}

.price {
  text-align: center;
  background-image: url("../assets/images/price_bg.png");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 100%;
  padding: 50px 0;
}
.price-table {
  border: 2px solid #2d3982;
  width: 100%;
  background: #fff;
}
.price-table tr td {
  border-bottom: 1px solid #2d3982;
  padding: 25px 20px;
  font-size: 1.5em;
  border-right: 1px solid #2d3982;
  color: #000;
}
.price-table tr td:nth-child(1) {
  font-size: 1.3em;
  font-weight: 600;
}
.price-table tr td:last-child {
  border-right: 0;
}
.price-table tr td b {
  color: #000;
  font-weight: 600;
}
.price-table tr td.price-td {
  font-size: 2em;
  font-weight: 800;
}
.price-table tr td.price-td2{
  font-size:1.8em;
  font-weight:600; 
}
.price-table tr td.txt-orange {
  color: #ff6000;
}
.price-td span {
  color: #838383;
  display: table;
  margin: 0 auto;
  font-size: 0.6em;
  font-weight: 600;
  position: relative;
}
.price-td span:before {
  display: block;
  content: "";
  width: 105%;
  height: 2px;
  background: #83ccff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-10deg);
}
.price a {
  background: #1e245b;
  color: #fff;
  display: inline-block;
  margin: 0 auto;
  /* margin-top: 40px; */
  margin-top:60px;
  min-width: 200px;
  padding: 10px 30px;
  border-radius: 40px;
  font-weight: 600;
  font-size: 1.4em;
}
.price p {
  /* font-size: 1.5em;
  text-decoration: underline;
  color: #000; */
}

.price-con {
  position: relative;
  margin: 0 auto;
}
.price-con label {
  display: block;
  overflow: hidden;
}
.price-con .title {
  position: relative;
}
.price-more {
  display: inline-block;
  margin: 40px auto;
  margin-bottom: 0;
  min-width: 200px;
  padding: 10px 30px;
}
.price-more::before {
  display: block;
  content: "가격 더보기";
  color: #3c6fff;
  text-decoration: underline;
  font-weight: 600;
  font-size: 1.4em;
}
.price-more:hover {
  cursor: pointer;
}
.price-con .title:hover {
}
.price-con .content {
  position: relative;
  padding: 0;
  max-height: 0px;
  transition: ease 0.8s;
}
.price-con input[type="checkbox"] {
  display: none;
}
.price-con input[type="checkbox"]:checked + .title table + p {
  /* display: none; */
}
.price-con input[type="checkbox"]:checked + .title table + .price-more::before {
  content: "가격 닫기";
}
.price-con input[type="checkbox"]:checked + .title {
  background: transparent;
}
.price-con input[type="checkbox"]:checked + .title + .content {
  max-height: 555px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.price-con input[type="checkbox"]:checked + .title + .content p {
  opacity: 1;
}
.discount {
  display: block;
  margin: 0 auto;
  max-width: 65px;
  border-radius: 25px;
  color: #fff;
  padding: 2px 10px;
  font-size: 0.8em;
  margin-top: 10px;
}
.bg-orange {
  background: #ff6000;
}
.bg-blue {
  background: #0096ff;
}

.b2b-txt {
  margin-top: 20px;
  font-size: 1.3em;
  color: #000;
  font-weight: 600;
}

/* 수강신청 */
.ap-btn:hover {
  color: red;
}
.ap-time-btn {
  width: 100%;
}
.comment-box {
 
}
.ap-course-map{
  overflow-y:scroll;
  height:280px;
}
.ap-course-map::-webkit-scrollbar {
  width: 8px;
}
.ap-course-map::-webkit-scrollbar-thumb {
  height: 30%;
  background: rgba(68, 68, 68, 0.4);
  border-radius: 10px;
}
.ap-course-map::-webkit-scrollbar-track {
  background: rgba(108, 108, 108, 0.2);
}
.course .con-tit {
  text-align: center;
}

.credit-button {
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #3b57ff;
  color: #fff;
}
.credit-button:hover {
  background-color: #0b24b6;
}

.course-wrap {
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
}
.course-box {
  width: 49%;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 3% 3%;
}
.course-b-top {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.course-b-top h2 {
  color: #000;
}
.course-b-top button {
  background: #fff;
  color: #555;
  font-size: 1em;
}
.course-table {
  width: 100%;
  border-bottom: 1px solid #ddd;
}
.course-table thead {
  /* border-bottom: 1px solid #ccc; */
  background: #e8efff;
  border-radius: 4px;
}
.course-table tr th {
  color: #111;
  font-size: 1em;
  padding: 10px 10px;
  text-align: center;
}
.course-table tr:hover {
  background: #f9f9f9;
}
.course-table thead tr:hover {
  background: #e8efff;
}
.course-table tr td {
  text-align: center;
  padding: 12px 10px;
  font-size: 1.1em;
}
.course-date {
  text-align: center;
  margin-bottom: 35px;
  border-bottom: 1px solid #ddd;
}
.course-date p {
  color: #111;
  font-size: 1em;
  padding: 10px 0;
  text-align: center;
  font-weight: 800;
  background: #e8efff;
}
.course-date-wrap {
  display: flex;
  justify-content: space-between;
  color: #111;
  font-size: 0.9em;
  font-weight: 600;
}
.course-total {
  text-align: center;
  padding: 12px 10px;
  font-size: 1.1em;
}
.course-total span {
}

.book-down {
  background: #788fd1;
  color: #fff;
  padding: 4px 5px;
  border-radius: 4px;
  transition: ease 0.3s;
}
.book-down:hover {
  background: #667ebe;
  color: #fff;
}
.c-chart-top {
  font-size: 1.2em;
  color: #000;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
}
.course-box2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.course-chart {
  padding: 0 2%;
}
.rep-pop .css-1xcrgwm {
  overflow-y: scroll;
}
.rep-pop .css-1xcrgwm::-webkit-scrollbar {
  width: 8px;
}
.rep-pop .css-1xcrgwm::-webkit-scrollbar-thumb {
  height: 30%;
  background: rgba(68, 68, 68, 0.4);
  border-radius: 10px;
}
.rep-pop .css-1xcrgwm::-webkit-scrollbar-track {
  background: rgba(108, 108, 108, 0.2);
}

.c-chart-wrap {
}
.c-chart {
  width: 16.6666666%;
  text-align: center;
}
.c-chart {
  width: 100%;
  min-height: 280px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
.c-graph {
  width: 70%;
}
.c-chart1 .c-graph {
  background: #1b2a87;
  height: 80%;
}
.c-chart2 .c-graph {
  background: #3b57ff;
  height: 30%;
}
.c-chart3 .c-graph {
  background: #10194f;
  height: 5%;
}
.c-chart4 .c-graph {
  background: #afb2c4;
  height: 1%;
}
.c-chart5 .c-graph {
  background: #6774c2;
  height: 4%;
}
.c-chart6 .c-graph {
  background: #3243ab;
  height: 8%;
}

.c-chart-tit {
  width: 100%;
  justify-content: space-between;
}
.c-chart-tit p {
  width: 16.6666666%;
  text-align: center;
  border-top: 1px solid #ccc;
  padding: 15px 0;
  color: #111;
  font-weight: 600;
}

.course-nav {
  text-align: center;
  padding: 10px;
}
.course-nav button {
  padding: 5px 15px;
  border-radius: 25px;
  border: 1px solid #bbb;
  margin: 0 5px;
}
.course-tab {
  width: 100%;
  height: 100%;
  position: relative;
}

.course-content {
  bottom: 17px;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: white;
  display: block;
}
#personal {
  display: block;
}
#corporate:target {
  display: block;
}

.edit_btn {
  cursor: pointer;
  color: #afb2c4;
}
.edit_btn:hover {
  color: #3b57ff;
}
.report_table {
  width: 100%;
  margin-bottom: 30px;
}
.report_table tr td,
.report_table tr th {
  border: 1px solid #aaa;
  color: #000;
  padding: 4px 0;
}
.report_table tr th {
  padding: 8px 0;
  color: #000;
  font-weight: 700;
  border-top: 1px solid #000;
}
.rep-tit {
  color: #000;
  text-align: left;
  font-size: 1.8em;
  margin-bottom: 10px;
  width: 100%;
}
.rep-down-btn {
  display: inline-block;
  vertical-align: middle;
  background: #1b2a87;
  color: #fff;
  border: 0;
  border-radius: 0;
  padding: 8px 14px;
  margin-left: 10px;
  margin-bottom: 4px;
  box-shadow: none;
  transition: all 0.4s;
  position: absolute;
  right: 25px;
}

/* 결제 */
.pay-container {
  max-width: 800px;
  width: 100%;
  margin: 50px auto;
}
.pay-top {
  border: 1px solid #ddd;
  margin-bottom: 20px;
}
.pay-amount {
  width: 100%;
  padding: 20px;
}
.pay-amount > div {
  width: 50%;
  display: inline-block;
  vertical-align: middle;
}
.pay-amo-wrap {
  width: 100%;
}
.pay-amo-wrap > div {
  width: 100%;
}
.coupon {
  text-align: center;
}
.coupon input {
  display: inline-block;
  margin-right: 4px;
}

.coupon label {
  font-size: 1.1em;
  color: #000;
  font-weight: 600;
}
.payment {
  width: 100%;
}
.payment span {
  width: 50%;
  height: 100%;
  padding: 20px 0;
  display: inline-block;
  text-align: center;
  color: #000;
  font-weight: 600;
  font-size: 1.3em;
}
.payment span.pay-bg-grey {
  background: #eee;
  font-size: 1.5em;
  font-weight: 800;
  text-decoration: underline;
  border: 2px solid #a3a3a3;
}
#payment-widget {
  border: 1px solid #ddd;
}
.pay-btn {
  background: #1b2a87;
  color: #fff;
  border: 0;
  border-radius: 0;
  display: block;
  padding: 15px 48px;
  margin: 40px auto;
  font-size: 1em;
}
.pay-cou {
  width: 100%;
  padding: 10px;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  font-family: "appleM";
  padding-top: 7%;
}

.pay-cou option[disabled] {
  color: #999;
}

.pay-cou option:not([disabled]):hover {
  background-color: #f5f5f5;
}

/* 학습현황 */
.tb-down-btn {
  background: #1b2a87 !important;
  color: #fff !important;
}
.report_box {
  overflow-y: scroll;
  position: relative !important;
}
.report_box::-webkit-scrollbar {
  width: 8px;
}

.report_box::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

.report_box::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
/* 커뮤니티 */
.commu-container {
}
.commu-container form .css-a5rdam-MuiGrid-root > .MuiGrid-item {
  margin-bottom: 3px;
}
.commu-container .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 0;
}
.commu-container
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border-radius: 0;
}
.commu-container .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  background: #fff;
}
.commu-container .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 0;
}
.commu-container .submit-btn {
  background: #1b2a87;
  color: #fff;
  border: 0;
  border-radius: 0;
  display: block;
  padding: 12px 48px;
  margin: 0 auto;
  margin-top: 30px;
  font-size: 1em;
  box-shadow: none;
  transition: all 0.4s;
}
.commu-container .submit-btn:hover {
  background-color: #0c1656;
}
.commu-container .commu-tit {
  font-size: 1.2em;
  font-weight: 700;
  text-align: center;
  color: #111;
  font-family: "AppleSDGothicNeo", "NotoSans", sans-serif;
  margin-bottom: 20px;
}
.faq-wrap {
  border-top: 1px solid #7d7d7d;
  border-bottom: 1px solid #7d7d7d;
  font-family: "AppleSDGothicNeo", "NotoSans", sans-serif;
}
.faq-wrap .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 20px; */
  /* padding:20px 0; */
  border-bottom: 1px solid #ddd;
}
.faq-wrap .faq-tit {
  padding: 30px 0;
}
.faq-wrap .faq-wrap-tit-i {
  font-size: 1.4em;
  font-weight: 800;
  margin-right: 20px;
}
.faq-wrap .faq-wrap-con-txt {
  font-size: 1.3em;
}
.faq-wrap .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded,
.faq-wrap .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
}
.faq-wrap .css-ahj2mt-MuiTypography-root {
  font-family: "AppleSDGothicNeo", "NotoSans", sans-serif;
  padding: 25px 20px;
  background: #f8f8f8;
  margin-bottom: 30px;
}

.faq-wrap .css-1elwnq4-MuiPaper-root-MuiAccordion-root:first-of-type {
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
}
.faq-wrap .css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  background: transparent;
}
.faq-wrap .css-pwcg7p-MuiCollapse-root {
  /* border-top: 1px solid #ddd; */
  /* padding-top: 20px; */
  /* padding:15px 16px;
  background:#f8f8f8;
  margin-bottom:30px;
  height:0; */
}
.faq-wrap .css-15v22id-MuiAccordionDetails-root {
  padding: 0;
}
.faq-wrap .css-o4b71y-MuiAccordionSummary-content {
  margin: 0;
}
.faq-wrap .css-1elwnq4-MuiPaper-root-MuiAccordion-root:first-of-type,
.faq-wrap .css-1elwnq4-MuiPaper-root-MuiAccordion-root:last-of-type,
.faq-wrap .css-1elwnq4-MuiPaper-root-MuiAccordion-root,
.faq-wrap .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  border-radius: 0;
  box-shadow: none;
}
.notice {
  /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); */
}
.notice-wrap.css-1ps6pg7-MuiPaper-root {
  margin-bottom: 0;
  box-shadow: none;
  border: 1px solid #ddd;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}
.notice-wrap.css-1ps6pg7-MuiPaper-root h2,
.notice-wrap.css-1ps6pg7-MuiPaper-root p {
  display: block;
}
.notice-wrap.css-1ps6pg7-MuiPaper-root p {
  margin-top: 20px;
}
.notice-wrap.css-1ps6pg7-MuiPaper-root:last-child {
}
.notice_img img {
  margin: 0 auto;
  display: block;
  max-width: 680px;
  width: 100%;
}
.commu-modal .css-jjys7k {
  width: 50%;
  height: 80vh;
}
.commu-modal .css-jjys7k h2 {
  text-align: center;
}
.commu-modal .css-jjys7k p {
  min-height: 75%;
  margin-top: 20px;
  /* border-radius:10px; */
  border-top: 1px solid #ddd;
  padding: 15px;
}
.commu-modal .notice-btn {
  display: block;
  margin: 20px auto;
  margin-top: 30px;
  background: #222;
  color: #fff;
  border-radius: 0;
  display: block;
  padding: 10px 20px;
}
.commu-modal .notice-btn:hover {
  background: #111;
  color: #fff;
}
.qna-wrap {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}
.qna-wrap .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root,
.qna-wrap
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.qna-wrap .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,
.qna-wrap .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 0;
}
.qna-wrap h4 {
  font-size: 1.1em;
  margin-bottom: 20px;
}
.qna-wrap form > div {
  margin-bottom: 10px;
}
.qna-select {
  min-width: 150px;
}
.qna-wrap label {
  background: #fff;
  border-radius: 0;
}
.qna-wrap .submit-btn {
  background: #1b2a87;
  color: #fff;
  border: 0;
  border-radius: 0;
  display: block;
  padding: 12px 48px;
  margin: 0 auto;
  margin-top: 30px;
  font-size: 1em;
  box-shadow: none;
  transition: all 0.4s;
}
.qna-wrap .submit-btn:hover {
  background: #1b2a87;
  color: #fff;
}

.link_box {
}
.link_item:visited {
  color: #000;
  /* font-weight: 600; */
}

/* 결제 정보 */
.pay-list {
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  font-size: 1.1em;
  padding-bottom: 10px;
}
.user_pay_form div span {
  margin-right: 14px;
}
.receipt_btn {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  float: right;
}

.receipt_btn:hover {
  background-color: #0056b3;
}

.receipt_btn:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.3);
}

.receipt_btn:active {
  background-color: #004699;
}
/* 공통 */
.logo-bg {
  background-image: url("../assets/images/bg_logo.png");
  background-size: initial;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 100px 0 80px 0;
  font-size: 2.5em;
  color: #111;
  font-weight: 600;
  font-family: "NotoSans", sans-serif;
  text-align: center;
}
.logo-bg.korean {
  font-size: 2.1em;
  line-height: 1.6;
  font-family: "AppleSDGothicNeo", sans-serif;
}
.logo-bg.korean span {
  font-weight: 500;
}
.align-center {
  text-align: center;
}
.pagination {
  text-align: center;
  margin-top: 30px;
}
.pagination .css-wjh20t-MuiPagination-ul {
  justify-content: center;
}

@media (max-width: 1600px) {
  .teacher-word-top {
    font-size: 2.3em;
  }
}

@media (max-width: 1200px) {
  .sub-top-txt {
    font-size: 4em;
    transition: ease 0.8s;
  }
  .logo-bg.korean,
  .logo-bg {
    font-size: 1.6em;
    transition: ease 0.8s;
  }
  .price {
    background-repeat: repeat;
  }
  .price .con-tit {
    font-size: 1.2em;
    transition: ease 0.8s;
  }
  .price-table {
    font-size: 0.9em;
  }
  .commu-modal .css-jjys7k {
    width: 80%;
  }
}
@media (max-width: 998px) {
  .sub-top-txt {
    font-size: 3em;
  }
  .class {
    background-position: right 30% center;
  }
  .logo-bg.korean {
    font-size: 1.4em;
  }
  .price .con-tit {
    font-size: 1em;
  }
  .price-table {
    font-size: 0.8em;
  }
  .price-table tr td {
    padding: 20px 5px;
  }
  .price-table tr td:first-child{
    padding: 20px 10px;
  }
  .price-more::before {
    font-size: 1.2em;
  }
  .price a{
    font-size:1.2em;
    min-width:160px;
  }
  .b2b-txt {
    font-size: 1em;
  }
  .discount {
    font-size: 0.8em;
    padding: 2px 5px;
  }
  .commu-modal .css-jjys7k {
    width: 90%;
  }
  .course-wrap {
    flex-direction: column;
  }
  .course-b-top h2 {
    font-size: 1.2em;
  }
  .course-box {
    width: 100%;
    margin-bottom: 30px;
    padding: 5% 5%;
  }
  .course-box2 {
    margin-bottom: 0;
  }
  .c-chart-tit {
    flex-direction: row;
  }
  .swiper-slide {
  }
  .teacher-name {
    font-size: 1.6em;
    margin-left: 20px;
  }
  .teacher-word {
    padding: 40px 0 0 0;
  }
  .teacher-profile {
    margin-bottom: 20px;
  }
  .teacher-word-top {
    font-size: 1.6em;
    min-height: 150px;
  }
  .teacher-page::before {
    top: 28%;
    background-size: 50%;
  }
  .teacher-page::after {
    right: 0;
    background-size: 50%;
  }
}
@media (max-width: 768px) {
  .sub-top {
    height: 50vh;
  }
  .sub-top-txt {
    font-size: 2em;
  }
  .sub-top-txt span {
    font-size: 0.6em;
  }
  .class {
    background-position: right 30% center;
  }
  .swiper-button-next {
    background-size: 100%;
    background-position: right center;
    width: 40px;
  }
  .swiper-button-prev {
    background-size: 100%;
    background-position: left center;
    width: 40px;
  }
  .teacher-page::before {
    top: 25%;
    left: 10%;
    background-size: 30%;
  }
  .teacher-name {
    font-size: 1.2em;
    margin-left: 12px;
  }
  .teacher-pic {
    width: 100px;
    height: 100px;
  }
  .teacher-word {
    min-height: 460px;
  }
  .teacher-word-top {
    font-size: 1.3em;
    min-height: 130px;
  }
  .teacher-page::after {
    bottom: 3%;
  }
  .teacher-page::after {
    bottom: 0;
    right: -5%;
    background-size: 30%;
  }
  .solution-wrap {
    padding: 20px 0;
  }
  .price {
    padding: 10px 0;
  }
  .price .con-tit {
    font-size: 0.9em;
  }
  .price-table {
    font-size: 0.7em;
  }
  .price-table tr td.price-td {
    font-size: 1.6em;
  }
  .price-td span {
    font-size: 0.8em;
  }
  .logo-bg {
    padding: 80px 0 50px 0;
    background-size: 30%;
  }
  .logo-bg.korean,
  .logo-bg {
    font-size: 1.2em;
  }
  .logo-bg.korean br {
    display: none;
  }
  .course .con-tit {
    font-size: 0.8em;
    margin-top: 120px;
  }
  .course-box {
    padding: 5% 3%;
  }
  .course-b-top h2 {
    font-size: 1.1em;
  }
  .course-b-top button {
    font-size: 0.8em;
  }
  .c-chart-tit p {
    font-size: 0.8em;
  }
  .tb-down-btn {
    font-size: 0.7em !important;
  }
  .user_pay_form div span {
    display: block;
  }
  .rep-tit {
    font-size: 1.2em;
  }
}
