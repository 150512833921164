.tab {
  display: none;
}
.tab-label {
  display: inline-block;
  /* padding: 10px;
  border: 1px solid #ccc;
  border-bottom: none;
  background-color: #f2f2f2; */
  /* width: 30%; */
  padding: 0 15px;
  border-radius: 25px;
  border: 1px solid #bbb;
  cursor: pointer;
  background: #fff;
  height: 36px;
  line-height: 36px;
  margin: 0 3px 30px 3px;
}
.tab-content {
  border: 1px solid #ccc;
  padding: 10px;
}
#tab1:checked ~ .monthly-box,
#tab2:checked ~ .weekly-box {
  display: block;
}
.monthly-box,
.weekly-box {
  display: none;
}

@media (max-width: 1440px) {
  .report_table {
    width: 1300px;
  }
  .monthly-box,
  .weekly-box {
    overflow-x:scroll;
  }
}

@media (max-width: 768px) {
  .tab-label{
    font-size:0.95em;
    padding:0 10px;
  }

}

