/* ||==========common==========|| */
.form-group {
  margin: 20px 0;
}
.form-group::after {
  display: block;
  content: "";
  clear: both;
}
.form-group label {
  display: block;
  width: 100%;
  color: #000;
  font-weight: 600;
  font-size: 1.1em;
  margin-bottom: 10px;
}
.form-group input {
  display: block;
  width: 100%;
  padding: 2px 0;
  height: 30px;
  border: 0;
  border-bottom: 1px solid #777;
  outline: none;
  margin-top: 6px;
  color: #888;
  font-weight: 500;
  font-size: 1.2em;
}
.form-group input::placeholder {
  /* color: #888;
  font-weight: 500;
  font-size: 1.2em; */
}
.form-check {
}
.form-check input {
  display: none;
}
.form-check label span {
  display: inline-block;
  font-size: 1em;
  color: #000;
  font-weight: 600;
}
.form-check input + label {
  cursor: pointer;
  position: relative;
}
.form-check input + label > span {
  vertical-align: middle;
  padding-left: 30px;
  padding-top: 2px;
}
.form-check input + label:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid #999;
  vertical-align: middle;
  background-image: url("images/check_btn.png");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: 50% 50%;
}
.form-check input:checked + label:after {
  content: "";
  background-image: url("images/check_btn_on.png");
  background-color: #1b2a87;
  border-color: #1b2a87;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: 50% 50%;
}
.flex50 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.flex50 .form-group {
  width: 48%;
}
.form-group input.w80 {
  width: 80%;
  display: inline-block;
}

.form-group .form-btn {
  color: #fff;
  background: #000;
  padding: 10px 0;
  width: 18%;
  float: right;
  display: block;
}
.form-group .form-btn.off {
  background: #aeaeae;
}
.submit-btn {
  background: #1b2a87;
  color: #fff;
  border: 0;
  border-radius: 0;
  display: block;
  padding: 15px 48px;
  margin: 0 auto;
  margin-top: 80px;
  font-size: 1em;
}

/* ||==========LoginLayout==========|| */
.join_box {
  width: 100%;
}
.login_box {
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
}
.member-top {
  font-size: 2em;
  color: #111;
  font-weight: 900;
  text-align: center;
  line-height: 1.1;
  margin-bottom: 80px;
}
.member-top span {
  font-size: 0.9em;
  font-weight: 500;
  display: block;
  margin-top: 20px;
}
.login-form {
  margin-bottom: 50px;
}

.login-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.login-lost a:hover {
  color: #111;
}
.login-lost a:nth-child(2) {
  padding-left: 5px;
}
.login-lost a:nth-child(2):before {
  display: inline-block;
  content: "|";
  padding-right: 5px;
  color: #555;
}

/* ||==========JoinLayout==========|| */
.privacy-of-use-check {
  margin-bottom: 80px;
}
.privacy-menu {
  display: flex;
}
[id*="open-accordion"],
[id*="close-accordion"] {
  display: block;
  margin: 0 auto;
  position: relative;
}
[id*="close-accordion"] {
  display: none;
}
.privacy-accordion a {
  color: #777;
  font-size: 0.9em;
  font-weight: normal;
  text-decoration: none;
  text-shadow: none;
  float: right;
  padding-right: 20px;
  padding-top: 5px;
  transition: 0.3s;
}
[id*="open-accordion"]:after,
[id*="close-accordion"]:after {
  right: 0;
  top: 7px;
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-top: 1px solid #777;
  border-right: 1px solid #777;
  transform: rotate(135deg);
  transition: 0.3s;
}
.target-fix {
  display: block;
  top: 0;
  left: 0;
  position: fixed;
}
.accordion-content {
  height: 0;
  background: #fff;
  position: relative;
  overflow: hidden;
  width: 100%;
  transition: all 0.1s ease;
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
}
.privacy-accordion span:target ~ .accordion-content {
  display: block;
  padding: 15px;
  height: 200px;
  overflow-y: scroll;
  border: 1px solid #ddd;
  margin-top: 50px;
}
.privacy-accordion span:target ~ [id*="close-accordion"] {
  display: block;
}
.privacy-accordion span:target ~ [id*="open-accordion"] {
  display: none;
}
.privacy-accordion span:target ~ [id*="close-accordion"]:after {
  transform: rotate(315deg);
  -webkit-transform: rotate(315deg);
  top: 10px;
}
.privacy-con::after {
  display: block;
  content: "";
  clear: both;
}
.privacy-con .form-check {
  float: left;
}
.privacy-con {
  border-top: 1px solid #7d7d7d;
  padding: 20px 20px;
}
.privacy-con:last-child {
  border-bottom: 1px solid #7d7d7d;
}

.identification {
  width: 100%;
  padding: 80px 50px;
  border: 1px solid #ddd;
  background: #f8f8f8;
  margin-bottom: 80px;
}
.identification p {
  width: 100%;
  font-size: 1.1em;
  text-align: center;
  margin-top: 20px;
  color: #000;
}
.id-btn {
  border: 3px solid #1b2a87;
  color: #1b2a87;
  background-color: #fff;
  border-radius: 0;
  display: block;
  padding: 15px 48px;
  margin: 0 auto;
  font-size: 1.2em;
  font-weight: 800;
  transition: all 0.4s;
}
.id-btn:hover {
  border: 3px solid #1b2a87;
  color: #fff;
  background-color: #1b2a87;
}
.privacy-of-use-check .personal-info {
  padding: 1%;
}

/* mypage */
.mypage .con-tit {
  text-align: center;
  position: relative;
}
.mypage .back-btn {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  left: 0;
  top: 22px;
  color: #555;
  font-size: 0.8em;
}
.mypage .back-btn::before {
  display: inline-block;
  vertical-align: middle;
  content: "<";
  font-family: "NotoSans", sans-serif;
  font-size: 1.1em;
  font-weight: 800;
  transform: scaleY(1.8) translateY(-1px);
  margin-right: 10px;
}
.tab-container {
  text-align: center;
}
.mypage .tab-label {
  width: auto;
}
.mypage label.active {
  background-color: #1b2a87;
  color: #fff;
}
/* 개인정보 수정 */
.user_info_form,
.user_pay_form {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 3% 3%;
  text-align: left;
}
.user_info_form .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 0;
}
.user_info_form .submit-btn.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  background: #1b2a87;
  color: #fff;
  border: 0;
  border-radius: 0;
  display: block;
  padding: 10px 48px;
  margin: 0 auto;
  margin-top: 80px;
  font-size: 1em;
}
.user_info_form .withdraw-btn.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  background-color: #f44336;
  color: #fff;
  border: 0;
  border-radius: 0;
  display: block;
  padding: 10px 48px;
  margin: 0 auto;
  margin-top: 80px;
  font-size: 1em;
  display: none;
}
.withdraw-button:hover {
  background-color: #d32f2f;
}

.withdraw-button:active {
  background-color: #b71c1c;
}
.new-pw-btn.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  display: inline-block;
  margin-top: 16px;
  height: 55px;
  line-height: 55px;
  border: 2px solid #1b2a87;
  color: #333;
  padding: 0;
  border-radius: 0;
  width: 100%;
}
.new-pw-btn.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
  border: 2px solid #1b2a87;
  color: #fff;
  background: #1b2a87;
}

.flex-radio.css-dmmspl-MuiFormGroup-root {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.flex-radio label {
  width: 50%;
}
.new-password {
  margin-top: 40px;
  font-size: 1.2em;
  color: #000;
}

@media (max-width: 768px) {
  .member-top {
    font-size: 1.2em;
    margin-top: 120px;
  }
  .mypage .con-tit {
    font-size: 0.8em;
    margin-top: 120px;
  }
  .user_info_form .css-17vbkzs-MuiFormControl-root-MuiTextField-root {
    margin-top: 2px;
  }
  .new-pw-btn.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    margin-top: 3px;
  }
  .user_info_form,
  .user_pay_form {
    padding: 10% 5%;
  }
  .user_info_form .submit-btn.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    margin-top: 30px;
  }
  .mypage .back-btn {
    top: -35px;
  }
  .payment .con-tit {
    font-size: 0.8em;
    margin-top: 120px;
  }
}
@media (max-width: 600px) {
  .privacy-con {
    padding: 20px 0;
  }
  .flex50 {
    flex-direction: column;
  }
  .flex50 .form-group {
    width: 100%;
  }
  .form-group input.w80 {
    width: 70%;
  }
  .form-group .form-btn {
    width: 28%;
  }
  .form-group.dummy {
    display: none;
  }
}
