@font-face {
  font-family: "appleM";
  src: url(../src/assets/fonts/AppleSDGothicNeoM.ttf);
}

body {
  margin: 0;
  padding: 0;
  font-family: "appleM";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
