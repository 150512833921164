/* modal */
#application-pop .checkbox {
  display: none;
}

/* Gray background */
#application-pop .pure-modal-overlay {
  opacity: 0;
  transition: all 0.3s ease;
  width: 50%;
  position: absolute;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -100;
  transform: scale(1);
  -webkit-transform: scale(1);
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Box */
#application-pop .pure-modal-wrap {
  background: #fdfbfb;
  border-radius: 15px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  letter-spacing: 0.05em;
  line-height: 1.6;
  width: 50%;
  margin: 20px auto;
  align-self: flex-start;
  transition: all 0.5s ease;
  overflow: hidden;
}
#application-pop .pure-modal-wrap.small {
  width: 30%;
}
#application-pop .pure-modal-wrap.full {
  width: 100%;
  height: 100%;
}

#application-pop .pure-modal-wrap.a-center {
  align-self: center;
}
#application-pop .pure-modal-wrap.from-left {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
}
#application-pop .pure-modal-wrap.from-right {
  transform: translateX(100%);
  -webkit-transform: translateX(-100%);
}
#application-pop .pure-modal-wrap.from-top {
  transform: translateY(-100%);
  -webkit-transform: translateY(-100%);
}
#application-pop .pure-modal-wrap.from-bottom {
  transform: translateY(100%);
  -webkit-transform: translateY(-100%);
}
.open-modal {
  display: inline-block;
}
.ap-pop {
}
.ap-pop-con {
  width: 100%;
}
.ap-con {
  width: 49.9%;
  padding: 3% 2% 1% 2%;
}
.ap-con {
  border-right: 1px solid #e4e4e4;
}
.ap-con:last-child {
  border-right: 0;
}

.ap-con select {
  width: 100%;
  border-radius: 10px;
  padding: 14px 10px;
  border-color: #ccc;
}
.rep-point table {
  width: 100%;
  height: 100%;
}
.rep-point table tr td {
  border: 1px solid #ddd;
  text-align: center;
  font-size: 1em;
  color: #000;
  padding: 8px 15px;
}
.rep-point table tr td:first-child {
  border-left: 0;
  text-align: left;
  font-weight: 600;
}
.rep-point table tr td:last-child {
  border-right: 0;
  color: #000;
  font-weight: 700;
}
.rep-fdb {
  display: block;
  width: 100%;
  word-break: break-all;
}

.ap-list-con {
  width: 100%;
  padding: 3% 2% 1% 2%;
  border-bottom: 1px solid #e4e4e4;
}
.ap-list-con .list {
  cursor: pointer;
  padding-bottom: 10px;
  font-size: 1.1em;
  display:table;
}
.ap-list-con .list label{
  display:table-cell;
}
.ap-list-con .list .ap-list-in{
  display:table-cell;
}
.ap-list-con .list span {
  padding-left: 4px;
  color: #000;
}
.ap-list-con .list span strong {
  padding: 0 2px 0 2px;
}
.ap-list-con .list span strong::after {
  display: inline-block;
  content: "|";
  font-weight: 400;
  margin-left: 5px;
}
.ap-list-con .list span:last-of-type strong::after {
  display: none;
}
.ap-date {
  display: block;
  width: 100%;
  background-color: #1b2a87;
}
.ap-date h1 {
  padding: 14px 0;
  text-align: center;
  color: #fff;
  display: block;
  margin: 0 auto;
  text-align: center;
  font-size: 1.3em;
  font-weight: 500;
}
.ap-con-tit {
  color: #111;
  display: inline-block;
  vertical-align: sub;
  margin-bottom: 20px;
}
.ap-con-tit::before {
  display: inline-block;
  content: "";
  background-color: #1b2a87;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-position: center;
  background-repeat: no-repeat;
  padding: 4px;
  margin-right: 5px;
  vertical-align: sub;
}
.ap-con-tit.ap-con-tit1::before {
  background-image: url("../assets/images/icon_ap1.png");
}
.ap-con-tit.ap-con-tit2::before {
  background-image: url("../assets/images/icon_ap2.png");
}
.ap-con-tit.ap-con-tit3::before {
  background-image: url("../assets/images/icon_ap3.png");
}
.ap-con-tit.ap-con-tit4::before {
  background-image: url("../assets/images/icon_ap4.png");
}

.ap-select option {
  padding: 10px 0;
}

.ap-time {
  display: flex;
  flex-wrap: wrap;
  height: 280px;
  overflow-y: scroll;
  padding-right: 3%;
  flex-direction: row;
  align-content: flex-start;
}
.ap-time::-webkit-scrollbar {
  width: 4px;
}
.ap-time::-webkit-scrollbar-thumb {
  height: 30%;
  background: #d8d8d8;
  border-radius: 10px;
}
.ap-time::-webkit-scrollbar-track {
  background: rgba(216, 216, 216, 0.5);
}
.ap-time.rep-time {
  overflow-y: hidden;
}

.ap-time button {
  display: block;
  width: 32%;
  border: 1px solid #ccc;
  padding: 12px 12px;
  font-size: 1.2em;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  margin-right: 2%;
}
.ap-time button:nth-child(3n) {
  margin-right: 0;
}
.ap-btn {
  color: #1b2a87;
  font-weight: 600;
  padding: 20px 20px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  font-size: 1.1em;
  background: #fff;
  border-top: 1px solid #ccc;
}
.ap-btn:hover {
  border-top: 1px solid #ccc;
}
.ap-delete {
  color: tomato;
}
.ap-delete,
.ap-edit {
}
/* Close button */
#application-pop .pure-modal-overlay .close {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background: #282c34;  */
  border-radius: 50%;
  justify-content: center;
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 1.3em;
  font-weight: 400;
  width: 30px;
  height: 30px;
  color: #fff;
}
#application-pop .pure-modal-overlay .close:hover {
  cursor: pointer;
  background-color: #d1d1d1;
  color: #4b5361;
  transition: all 0.3s ease;
}
#application-pop .o-close {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -100;
}
#application-pop input:checked ~ .o-close {
  z-index: 9998;
}
#application-pop input:checked ~ .pure-modal-overlay {
  transform: scale(1);
  -webkit-transform: scale(1);
  opacity: 1;
  z-index: 9997;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  animation-duration: 0.5s;
  animation-name: fade-in;
  -moz-animation-duration: 0.5s;
  -moz-animation-name: fade-in;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-name: fade-in;
}
#application-pop input:checked ~ .pure-modal-overlay::-webkit-scrollbar {
  display: none;
}
#application-pop input:checked ~ .pure-modal-overlay .pure-modal-wrap {
  transform: translateY(0);
  -webkit-transform: translateY(0);
  z-index: 9999;
}

@keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: flex;
    opacity: 0;
  }
  100% {
    display: flex;
    opacity: 1;
  }
}

@-moz-keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: flex;
    opacity: 0;
  }
  100% {
    display: flex;
    opacity: 1;
  }
}

@-webkit-keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: flex;
    opacity: 0;
  }
  100% {
    display: flex;
    opacity: 1;
  }
}

.calendar-pop-btn {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

/* 수강신청 달력*/
.application .con-tit {
  text-align: center;
}
.application .con-tit p {
  display: inlnine-block;
  margin-top: 10px;
  margin-bottom: 20px;
}
.application .con-tit p::after {
  display: block;
  content: "";
  width: 30px;
  height: 1px;
  background: #3b57ff;
  margin: 20px auto;
}
.calendar {
  position: relative;
}
.cal-top-wrap.flex {
  /* background: #fff;
  border-radius: 20px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin:30px auto;
  padding:10px 0; */
  margin: 30px auto;
  /* border-top:1px solid #777; */
  /* background:#f8f8f8;
  border-radius:10px; */
}
.cal-info {
  text-align: center;
  padding: 5px 10px;
  /* background: #eee;
  border-radius: 10px; */
  display: block;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
  /* border-bottom:1px solid #ddd; */
}
.credit-container {
  margin-bottom: 30px;
}
.credit-container .cal-credit-list{
  border: 1px dashed #ccc;
  margin-left:0;
}
.credit-container .cal-credit-list > div{
  border-right: 1px dashed #ccc;
  padding:10px 15px;
  padding-left:25px;
  padding-top:10px;
}
.credit-container .cal-credit-list > div:nth-child(3){
  border-right: 0;
}
.cal-info p {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.2em;
  color: #111;
  margin-right:20px;
  /* margin-bottom: 20px; */
}
.cal-info p:last-child{
  margin-right:0;
}
.cal-info p span {
  font-weight: 700;
  padding-right:5px;
}
.cal-info p::before {
  display: inline-block;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #8395ff;
  vertical-align: middle;
  margin-right: 5px;
}
.cal-info p::after {
  display: none;
  content: "|";
  color: #555;
  margin: 0 10px;
}
.cal-info p:last-child::after {
  display: none;
}
.cal-data {
  font-size: 1.3em;
  font-weight:600;
  color: #000;
  display: block;
  position: relative;
}
.cal-data::after {
  display: none;
  content: "";
  width: 100%;
  height: 1px;
  background: #000;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 4px;
}
.cal-data span.colorBlue {
  padding: 0 4px;
  font-weight: 900;
}

.react-calendar {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.react-calendar__navigation {
  text-align: center;
  font-size: 1.2em;
  padding: 24px 0;
  /* border-bottom: 1px solid #dedee6; */
}
.react-calendar__navigation button {
  background-color: #fff;
  margin: 0 0.5%;
}
.react-calendar__navigation__label {
  text-align: center;
}
.react-calendar__navigation__label__labelText {
  font-size: 2em;
  /* font-family: 'BlackHanSans',sans-serif; */
  font-weight: 800;
  border: 0;
}
.react-calendar__navigation__arrow {
  /* font-size:2em; */
  text-indent: -9999px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #fff;
  width: 18px;
  height: 12px;
}
.react-calendar__navigation__prev2-button {
  background-image: url("../assets/images/cal_arrow2.png");
}
.react-calendar__navigation__prev-button {
  background-image: url("../assets/images/cal_arrow1.png");
}
.react-calendar__navigation__next2-button {
  background-image: url("../assets/images/cal_arrow2.png");
  transform: rotate(180deg);
}
.react-calendar__navigation__next-button {
  background-image: url("../assets/images/cal_arrow1.png");
  transform: rotate(180deg);
}

.react-calendar__month-view__weekdays__weekday {
  text-align: center;
  padding: 16px 0;
  font-weight: 600;
  font-size: 1.1em;
  border-bottom: 1px solid #dedee6;
}
.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}
.react-calendar__month-view__days__day {
  min-height: 16vh;
  background: #fff;
  border-right: 1px solid #cbcbcb;
  border-bottom: 1px solid #cbcbcb;
  position: relative;
}
.react-calendar__month-view__days__day::before {
  display: none;
  content: "";
  width: 40px;
  height: 40px;
  border-radius: 20px;
  position: absolute;
  background: #f8f8f8;
  left: 50%;
  bottom: 10px;
  transform: translate(-50%, 0);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
}
.react-calendar__month-view__days__day::after {
  display: none;
  content: "+";
  position: absolute;
  /* font-family: "BlackHanSans"; */
  font-family: "AppleSDGothicNeo", sans-serif;
  font-size: 3em;
  font-weight: 900;
  color: #dfdfdf;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
}
.react-calendar__month-view__days__day.react-calendar_drenglish_disabled::before,
.react-calendar__month-view__days__day.react-calendar-no-credits::before,
.react-calendar__month-view__days__day.react-calendar_drenglish_disabled::after,
.react-calendar__month-view__days__day.react-calendar-no-credits::after {
  display: none;
}
.react-calendar__month-view__days__day:nth-child(7n) {
  border-right: 0;
}
.react-calendar__month-view__days__day abbr {
  position: absolute;
  right: 10px;
  top: 10px;
  font-weight: 600;
  color: #555;
}
.react-calendar__month-view__days__day:hover {
  border-right: 1px solid #e3e3e9;
  border-bottom: 1px solid #e3e3e9;
}

.react-calendar__month-view__days__day--neighboringMonth {
  background-size: 42.4px 42.4px;
  background: none !important;
  cursor: default !important;
}
.react-calendar__month-view__days__day--neighboringMonth abbr {
  color: rgba(0, 0, 0, 0) !important;
}
.react-calendar_national_holiday {
  background: rgb(248, 158, 158);
  width: 100%;
  height: 100%;
  cursor: default;
  pointer-events: none;
}
.react-calendar_drenglish_disabled {
  background: #bbb;
  width: 100%;
  height: 100%;
  cursor: default;
  pointer-events: none;
}
.react-calendar_drenglish_disabled .react-calendar_drenglish_event {
  cursor: pointer;
}
.react-calendar_drenglish_event_container {
  position: absolute;
  top: 34px;
  width: 100%;
  left: 0;
}

.react-calendar__month-view__days__day--neighboringMonth {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.react-calendar-no-credits {
  background-color: #f3f3f3;
  pointer-events: none;
  cursor: default !important;
}
.react-calendar-no-credits .react-calendar_drenglish_event {
  pointer-events: auto;
  cursor: pointer !important;
}
.total-date {
  /* display:inline-block; */
  color: #777;
  text-decoration: none;
  margin-top: 5px;
  font-weight: 600;
  font-size:0.9em;
}
.plus-badge {
  position: absolute;
  right: 12%;
}
.calendar-today {
  border: 4px solid #f79f6f;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.react-calendar_drenglish_event {
  display: block;
  /* background: #d7dcf9; */
  background: #0f3781;
  color: #fff;
  position: absolute;
  padding: 10px 2px;
  top: 0;
  width: 95%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 30px;
  font-size: 1.1em;
  letter-spacing: -0.05em;
  z-index: 1;
}
.react-calendar_drenglish_event:hover {
  box-shadow: 0 7px 18px 0 rgba(51, 51, 51, 0.24),
    0 7px 30px 0 rgba(85, 85, 85, 0.19);
}
.react-calendar__month-view__days__day:hover {
  box-shadow: 0 10px 30px 0 rgba(51, 51, 51, 0.24),
    0 10px 50px 0 rgba(85, 85, 85, 0.19);
}
.end_event {
  background: #d9d9db;
  color: #000;
}
button > .past_date {
  pointer-events: none;
}

button > .past_date > div {
  pointer-events: auto;
}

button.past_date {
  pointer-events: none;
}

button.past_date:hover > div {
  pointer-events: auto;
}

us-badge {
  position: absolute;
  right: 30px;
  text-decoration: underline;
}
.react-calendar__year-view__months {
  border-top: 1px solid #cbcbcb;
}
.react-calendar__year-view__months button {
  pointer-events: auto;
  background-color: white;
  border-right: 1px solid #cbcbcb;
  border-bottom: 1px solid #cbcbcb;
  min-height: 14vh;
  font-size: 1.3em;
}
.react-calendar__year-view__months button:nth-child(3n) {
  border-right: 0;
}
.react-calendar__year-view__months
  button:nth-child(3n).react-calendar__tile--now {
  border: 2px solid orange;
  border-right: 2px solid orange;
}
.react-calendar__year-view__months button:nth-child(10),
.react-calendar__year-view__months button:nth-child(11),
.react-calendar__year-view__months button:nth-child(12) {
  border-bottom: 0;
}
.react-calendar__year-view__months
  button:nth-child(10).react-calendar__tile--now,
.react-calendar__year-view__months
  button:nth-child(11).react-calendar__tile--now,
.react-calendar__year-view__months
  button:nth-child(12).react-calendar__tile--now {
  border: 2px solid orange;
  border-bottom: 2px solid orange;
}
.react-calendar__year-view__months .react-calendar__tile--now {
  border: 2px solid orange;
}
.react-calendar__year-view__months .react-calendar__tile {
  cursor: pointer !important;
  box-sizing: border-box;
}
.react-calendar__year-view__months .react-calendar__tile:hover {
  color: rgb(255, 115, 0);
}

/* .react-calendar__decade-view__years button {
  pointer-events: auto;
  background-color: white;
}
.react-calendar__decade-view__years .react-calendar__tile--now {
  border: 2px solid orange;
}
.react-calendar__decade-view__years .react-calendar__tile {
  cursor: pointer !important;
  box-sizing: border-box;
}
.react-calendar__decade-view__years .react-calendar__tile:hover {
  font-weight: bold;
} */
.react-calendar__decade-view__years {
  border-top: 1px solid #cbcbcb;
}
.react-calendar__decade-view__years button {
  pointer-events: auto;
  background-color: white;
  border-right: 1px solid #cbcbcb;
  border-bottom: 1px solid #cbcbcb;
  min-height: 14vh;
  font-size: 1.3em;
}
.react-calendar__decade-view__years button:nth-child(3n) {
  border-right: 0;
}
.react-calendar__decade-view__years
  button:nth-child(3n).react-calendar__tile--now {
  border: 2px solid orange;
  border-right: 2px solid orange;
}
.react-calendar__decade-view__years button:nth-child(10),
.react-calendar__decade-view__years button:nth-child(11),
.react-calendar__decade-view__years button:nth-child(12) {
  border-bottom: 0;
}
.react-calendar__decade-view__years
  button:nth-child(10).react-calendar__tile--now,
.react-calendar__decade-view__years
  button:nth-child(11).react-calendar__tile--now,
.react-calendar__decade-view__years
  button:nth-child(12).react-calendar__tile--now {
  border: 2px solid orange;
  border-bottom: 2px solid orange;
}
.rreact-calendar__decade-view__years .react-calendar__tile--now {
  border: 2px solid orange;
}
.react-calendar__decade-view__years .react-calendar__tile {
  cursor: pointer !important;
  box-sizing: border-box;
}
.react-calendar__decade-view__years .react-calendar__tile:hover {
  color: rgb(255, 115, 0);
}

/* .react-calendar__century-view button {
  pointer-events: auto;
  background-color: white;
}
.react-calendar__century-view .react-calendar__tile--now {
  border: 2px solid orange;
}
.react-calendar__century-view .react-calendar__tile {
  cursor: pointer !important;
  box-sizing: border-box;
}
.react-calendar__century-view .react-calendar__tile:hover {
  font-weight: bold;
} */

.react-calendar__century-view {
  border-top: 1px solid #cbcbcb;
}
.react-calendar__century-view button {
  pointer-events: auto;
  background-color: white;
  border-right: 1px solid #cbcbcb;
  border-bottom: 1px solid #cbcbcb;
  min-height: 14vh;
  font-size: 1.3em;
}
.react-calendar__century-view button:nth-child(3n) {
  border-right: 0;
}
.react-calendar__century-view button:nth-child(3n).react-calendar__tile--now {
  border: 2px solid orange;
  border-right: 2px solid orange;
}
.react-calendar__century-view button:nth-child(10),
.react-calendar__century-view button:nth-child(11),
.react-calendar__century-view button:nth-child(12) {
  border-bottom: 0;
}
.react-calendar__century-view button:nth-child(10).react-calendar__tile--now,
.react-calendar__century-view button:nth-child(11).react-calendar__tile--now,
.react-calendar__century-view button:nth-child(12).react-calendar__tile--now {
  border: 2px solid orange;
  border-bottom: 2px solid orange;
}
.rreact-calendar__century-view .react-calendar__tile--now {
  border: 2px solid orange;
}
.react-calendar__century-view .react-calendar__tile {
  cursor: pointer !important;
  box-sizing: border-box;
}
.react-calendar__century-view .react-calendar__tile:hover {
  color: rgb(255, 115, 0);
}
.color-wrap {
  display: block;
  margin:10px auto;
  /* width: 500px; */
}
.color-info {
  display: flex;
  justify-content: center;
}
.day-info {
  line-height: 30px;
  display:inline-block;
  vertical-align: middle;
  padding:0 15px;
  font-size:0.9em;
  color:#777;
  font-weight:600;
}
.day-info::before{
  display:inline-block;
  content:'';
  vertical-align: middle;
  width:20px;
  height:20px;
  border-radius:5px;
  margin-right:4px;
}
.day-info:nth-child(1)::before{
  background-color: #bbb;
}
.day-info:nth-child(2)::before{
  background: rgb(248, 158, 158);
}
.day-info:nth-child(3)::before{
  background-color: #eee;
}
.day-info:nth-child(4)::before{
  border: 1px solid #cbcbcb;
}
.no-credit{
  border:1px dashed #ccc;
  text-align:center;
  padding:10px 10px;
  width:100%;
  display:block;
  margin-bottom:25px;
  color:#111;
  font-weight:600;
}
.no-credit:hover{
  border:1px dashed #3b57ff;
  color:#3b57ff;
}
.no-credit button{
  background:#222;
  color:#fff;
  padding:6px 10px;
  border-radius:6px;
  margin-left:10px;
}
.no-credit button:hover{
  background:#000;
}

.color-box {
  width: 30px;
  height: 30px;
  margin: 0 15px;
  border-radius: 20%;
}
.bbb-box {
  background-color: #bbb;
}
.red-box {
  background: rgb(248, 158, 158);
}
.f2-box {
  background-color: #f3f3f3;
}
.none-box {
  border: 1px solid #f2f2f2;
}
@media (max-width: 1600px) {
  #application-pop .pure-modal-wrap {
    width: 80%;
  }
}
@media (max-width: 1024px) {
  .cal-top-wrap.flex {
    flex-direction: column;
  }
  .cal-info p {
    font-size: 1em;
  }
  .cal-data {
    font-size: 1.1em;
  }
}
@media (max-width: 998px) {
  .ap-con-tit {
    font-size: 1.1em;
    padding: 0;
  }
  .ap-con-tit::before {
    transform: scale(0.8);
    vertical-align: middle;
  }
  .rep-point table tr td {
    font-size: 0.9em;
  }
  .ap-time.rep-time h3 {
    font-size: 1em;
  }
  .react-calendar_drenglish_event {
    font-size: 1em;
  }
  
}
@media (max-width: 768px) {
  #application-pop .pure-modal-wrap.a-center {
    align-self: auto;
  }
  .application .con-tit {
    font-size: 0.8em;
    margin-top: 120px;
  }
  .cal-data{
    font-size:1em;
  }
  .cal-info {
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
  }
  .credit-container .cal-credit-list{
    display:block;
  }
  .credit-container .cal-credit-list > div{
    border-right: 0;
    padding: 10px 15px;
    padding-left: 15px;
    width:100%;
    max-width:100%;
    border-bottom:1px dashed #ccc
  }
  .credit-container .cal-credit-list > div:last-child{
    border-bottom:0;
  }
  .total-date{
    font-size:0.95em;
  }
  #application-pop .pure-modal-wrap {
    width: 88%;
  }
  .ap-pop-con {
    flex-direction: column;
  }
  .ap-con {
    width: 100%;
    border-right: 0;
    border-bottom: 1px solid #e4e4e4;
    padding: 3% 5%;
  }
  .ap-btn-wrap{
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  .ap-list-con {
    padding: 3% 5%;
  }
  .ap-con:last-child {
    border-bottom: 1px solid #e4e4e4;
  }
  .ap-time {
    height: auto;
  }
  .ap-list-con .list:last-child {
    padding-bottom: 0;
  }
  .ap-date h1 {
    font-size: 1.1em;
  }
  .ap-date h1 {
    padding: 14px 0 10px 0;
  }
  #application-pop .pure-modal-overlay .close {
    top: 12px;
  }
  .react-calendar_drenglish_event {
    text-indent: -9999px;
    width: 10px;
    height: 10px;
    padding: 0;
  }
  .cal-data {
    margin: 3px auto;
  }
  .react-calendar__navigation {
    padding: 20px 0 5px 0;
    line-height: 1.1em;
  }
  .react-calendar__navigation__label__labelText {
    font-size: 1.3em;
  }
  .react-calendar__month-view__weekdays__weekday abbr {
    font-size: 0.8em;
  }
  .react-calendar__navigation__arrow {
    height: 18px;
  }
}

@media (max-width: 600px) {
  .react-calendar__year-view__months button,
  .react-calendar__decade-view__years button,
  .react-calendar__century-view button {
    font-size: 1em;
  }
  .cal-info {
    text-align: left;
  }
  .cal-info p {
    margin-bottom: 4px;
    display: block;
  }
  .cal-info p::after {
    display: none;
  }
  .credit-button{
    display:table;
    width:96%;
    margin:0 auto;
    margin-top:10px;
  }
  .react-calendar__month-view__days__day {
    min-height: 10vh;
  }
  .react-calendar__month-view__days__day abbr {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
  .react-calendar__month-view__days__day:nth-child(3)::after {
    content: "";
    width: 10px;
    height: 10px;
    padding: 0;
    top: auto;
    left: 20px;
    bottom: 20px;
  }
  .react-calendar__month-view__days__day:nth-child(3)::before {
    content: "";
    width: 10px;
    height: 10px;
    padding: 0;
    top: auto;
    left: 40px;
    bottom: 20px;
  }
  .color-wrap {
    /* width: 320px; */
  }
  .color-info {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  
  .day-info::before{
    width: 15px;
    height: 15px;
  }
  .color-box {
    width: 15px;
    height: 15px;
    margin: 0 5px;
    border-radius: 20%;
  }
  .day-info {
    width:50%;
    line-height: 15px;
    font-size: 0.9em;
    padding-bottom:10px;
  }
  .day-info::before{
    margin-right:6px;
  }
}
